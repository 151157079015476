import { DataService } from 'src/app/services/data.service';
import { AuthService } from '../../../services/auth.service';
import { Map } from 'leaflet';
import { GeoLocationService } from '../../../services/geolocation.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { latLng, Layer, marker, tileLayer } from 'leaflet';
import * as L from "leaflet";
import { HttpService } from 'src/app/services/http.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { TemplateRef } from '@angular/core';



// import {OpenStreetMapProvider} from 'leaflet-geosearch';
declare var L: any;

@Component({
  selector: 'app-team-tracker',
  templateUrl: './team-tracker.component.html',
  styleUrls: ['./team-tracker.component.scss']
})
export class TeamTrackerComponent implements OnInit, OnDestroy {

  filterStatus = '';
  mapFilter;
  projectMapLists = [];
  markers: Layer[] = [];
  allEmployeeList = [];
  employeeList = [];
  employeeLastLoc = [];
  projectList = [];
  projectId: any;


  mapArray = [];
  searchArray = [];
  leafletFitBounds:any
  mapZoom = 14
  selectedProject: any;
  subscriptionKey: any;
  isSearchEmptyArray = false;
  isValidate = false;
  myMap = null;
  count = 0;
  searchText: string;
  isLoading = false;
  searchUserForm: FormGroup;
  private mapSub: Subscription[] = [];

  // set icon and size!
  greenIcon = L.icon({

    iconUrl: '../../assets/images/location.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor,

  });
  logoutIcon = L.icon({

    iconUrl: '../../assets/images/Location_logout.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor,

  });


  isDialogOpen = false;

  @ViewChild('sidenav') sidenav: any;

  //  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);

  pageSize = 20;
  length = 20;
  displayNoRecords: boolean;
  userData: any;

  employee = new FormControl();
  isLoadingResults = false;
  selected_count = 0;
  osmAttribution = 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors,' +
    ' <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';
  // Open Street Map definitions
  LAYER_OSM = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: this.osmAttribution });
  options = {
    layers: [this.LAYER_OSM],
    zoom: this.mapZoom,
    center: latLng(13.048472,80.177490)
  };
  mapCenter = new L.LatLng(13.048472, 80.177490)

  // Values to bind to Leaflet Directive

  liveData:any
  liveDataChannelName = `location/${this.authService.selectedOrgId}/+`
  public map:Map
  selectedOrgId:string = localStorage.getItem("selectedOrgId")
  @ViewChild('sendNotificationDialog', { static: true }) sendNotificationDialog: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private http: HttpService,
    private authService:AuthService,
    private geoLocationService : GeoLocationService,
    private datepipe : DatePipe,
    private dataService: DataService
    ) {
      this.userData = JSON.parse(localStorage.getItem('data'));
      this.searchUserForm = formBuilder.group({
      pid: this.projectId,
      employee: this.employee,
    });
    this.authService.selectedOrgId.subscribe(id=>{
      this.selectedOrgId = id
      this.initLoad()
    })
  }

  ngOnInit() {
    this.initLoad()
  }

  initLoad() {
    this.getProjectList()
    this.getProjectBoundaries()
    this.getEmployeeList()
    this.geoLocationService.watchChannel(this.liveDataChannelName)
    this.geoLocationService.geoData$.subscribe((data) => {
      //get the empolyee name from existing data and show the marker
      this.liveData = data
      this.moveMarker(data)
    });
  }


  onMapReady(map: Map) {
    this.map = map;
  }

  ngOnDestry() {
    this.geoLocationService.channelUnSubscription(this.liveDataChannelName)
    this.geoLocationService.disconnect()
  }

  getProjectList() {
    this.projectList = []
    this.selectedProject = {}
    this.mapSub.push(
     this.dataService.getProjectList().subscribe((res: any) => {
       if(res) {
         this.projectList = res;
         this.searchUserForm.controls.pid.setValue(this.projectList[0]._id);
         this.selectedProject = this.projectList[0];
       }
     })
    )
  }

  getProjectBoundaries() {
    this.projectMapLists = []
    this.mapSub.push(
     this.http.getDocListBySearch('project_map',{status:'A'}).subscribe((result: any) => {
       if(result) {
         this.projectMapLists = result;
       }
     })
    )
   }

  getEmployeeList() {
    this.allEmployeeList = []
    this.mapSub.push(
      this.dataService.getEmployeeList().subscribe((result: any) => {
        if(result) {
          this.allEmployeeList = result
          //by default select all the employees
          this.allEmployeeList.map(emp=>{emp["is_select"] = true})
        }
        this.filterProjectEmployees()
      })
    )
  }

  filterProjectEmployees() {
    this.employeeList = this.allEmployeeList.filter(o => o.pid == this.selectedProject._id);
    this.employeeList = _.sortBy(this.employeeList, "name")
    this.loadMap();
    this.showProjectBoundary()
  }

  getEmployeeDetails(event){
    var id = event.value;
    var d = this.projectList.filter(o => o._id == id);
    if(d.length) {
      this.selectedProject = d[0];
      this.filterProjectEmployees()
    }
  }

  moveMarker(data) {
    var m:marker = this.findMarkerByKey(data.emp_id)
    if (!m) {
        var emp = this.employeeList.find(e=>e._id==data.emp_id)
        if (emp) {
          emp["last_seen"] = {lat:data.lat,lng:data.lng,dt:data.dt}
          this.addMarker(emp)
        }
    }
    m.setLatLng(new L.LatLng(data.lat, data.lng));
    //const customPopup = emp["name"] + "<br/> <img src= '" + emp.image + "' height='50' width='50' />" + (emp["dt"]? "<br/>Last Seen:" + this.showDate(emp["dt"]) : "");
  }
  centerMapOnMarker(latLngs) {
    if (!latLng) return
    this.leafletFitBounds = L.latLngBounds(latLngs);
   // this.map.fitBounds(markerBounds);
  }

  changeMakerOpacity(empId:string,opacity:number) {
    var m:marker = this.findMarkerByKey(empId)
    m.setOpacity(opacity)
  }

  findMarkerByKey(id:string) {
    return this.markers.find(m=>m.options.key == id)
  }

  loadMap() {
    this.markers = [];
    //load project boundary
    this.employeeList.forEach(emp => {
        this.addMarker(emp)
    });
  }

  showDate(dt:any) {
    return this.datepipe.transform(dt, 'EEE, MMM d, y, h:mm:ss a')
  }

  addMarker(emp:any) {
    if (!emp.last_seen) return
    if (!emp.image) {
      emp.image = '../../../assets/images/svg/userprofile.svg';
    }
    const customPopup = emp["name"] + "<br/> <img src= '" + emp.image + "' height='50' width='50' />" + (emp["last_seen"]? "<br/>Last Seen:" + this.showDate(emp["last_seen"].dt) : "");
    const newMarker = marker([emp.last_seen.lat, emp.last_seen.lng],
      { title:emp["name"],
        timestamp:emp["dt"],
        key:emp["_id"],
        icon: new L.DivIcon({
          className: 'div-icon',
          html: '<img class="div-image" src="../../assets/images/location.png" width="50"/>'+
                '<span class="div-span-name">' + emp["name"] + '</span>'
          })
      }
    ).bindPopup(customPopup).on('click', function () { this.openPopup(); });
    this.markers.push(newMarker);
  }

  showProjectBoundary() {
    var projectMap = this.projectMapLists.filter(p=>p.pid == this.selectedProject._id)
    if (projectMap.length == 0 ) return
    projectMap.forEach(map=>{
        if (map["type"] == "circle") {
          var layer = L.circle(map["boundary"], map["radius"], map["options"]);
          // latlng = new L.LatLng(projectMap["cp_lat"],projectMap["cp_lat"])
        } else if (map["type"] == "rectangle") {
          var layer = L.rectangle(map["boundary"], map["options"]);
        } else if (map["type"] == "polygon") {
          var layer = L.polygon(map["boundary"], map["options"]);
        }
        this.markers.push(layer)
    })
    this.mapCenter = new L.LatLng(projectMap[0]["cp_lat"],projectMap[0]["cp_lng"])
    this.mapZoom = projectMap[0]["zoom"]
  }


  /*****
     *  Search Filter Function Used to Find the Vessel
     */
  async onSearchFilter() {
    this.markers = [];
    this.searchArray.forEach(element => {
      if (element.is_select) {
        if (element.image) {
          element.image = element.image;
        } else {
          element.image = '../../../assets/images/svg/userprofile.svg';
        }
        // if (element) {
        //   if (element.is_login) {
        //     const newMarker = marker([element.last_seen.lat, element.last_seen.lng],
        //       {
        //         icon: icon({
        //           iconSize: [50, 50], // size of the icon
        //           shadowSize: [50, 64], // size of the shadow
        //           shadowAnchor: [4, 62], // the same for the shadow
        //           popupAnchor: [-3, -40], // point from which the popup should open relative to the iconAnchor,
        //           iconUrl: '../../assets/images/location.png'
        //         })
        //       }
        //     ).bindPopup(customPopup).on('click', function () { this.openPopup(); });
        //     this.markers.push(newMarker);
        //   } else {
        //     const newMarker = marker([element.latitude, element.longtitude],
        //       {
        //         icon: icon({
        //           iconSize: [50, 50], // size of the icon
        //           shadowSize: [50, 64], // size of the shadow
        //           shadowAnchor: [4, 62], // the same for the shadow
        //           popupAnchor: [-3, -40], // point from which the popup should open relative to the iconAnchor,
        //           iconUrl: '../../assets/images/Location_logout.png'
        //         })
        //       }
        //     ).bindPopup(customPopup).on('click', function () { this.openPopup(); });
        //     this.markers.push(newMarker);
        //   }
        // }
      }
    });
  }

  openDialog() { }
  /**
   * This is Life cycle  hooks and it used unsubscribe the subscribe method value in order to avoid the memory Leakage
   *
   * @memberof MapComponent
   */
  ngOnDestroy(): void {
    this.mapSub.forEach(sub => sub.unsubscribe())
  }


  toggleAllSelection(index) {
    //this.searchArray = [];
    this.employeeList[index].is_select = !this.employeeList[index].is_select;
    if (this.employeeList[index].is_select) {
      this.changeMakerOpacity(this.employeeList[index]["_id"],1)
    } else {
      this.changeMakerOpacity(this.employeeList[index]["_id"],0.3)
    }
    // this.employeeList.forEach(emp => {
    //   if (emp.is_select == true) {
    //     this.searchArray.push(emp);
    //   }
    // });
    // this.onSearchFilter('', this.searchArray, 'choose');
  }
}
