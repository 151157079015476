export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  entity?: string;
  children?: NavItem[];
}

export const defaultMenu: NavItem[] = [
  { displayName: 'Dashboard', iconName: 'apps', route: '/' },
  { displayName: 'Live Tracking', route: '/live/team-tracker', iconName: 'room' },
  { displayName: 'Leave Request List', route: '/page/leave-request', iconName: 'person_outline', entity: 'leave' },
  {
    displayName: 'Attendance Report', route: null, iconName: 'file_copy', entity: 'reports',
    children: [
      { displayName: 'Day-wise', route: '/list/attendance', iconName: 'calendar_today', entity: 'employee' },
      { displayName: 'Employee-wise', route: '/employee/report', iconName: 'supervised_user_circle', entity: 'employee' }
    ]
  },
  { displayName: 'Notification List', route: '/page/notification', iconName: 'notification_important', entity: 'notification' },
  {
    displayName: 'Master Data', route: null, iconName: 'settings', entity: 'reports',
    children: [
      { displayName: 'Employee List', route: '/page/employee', iconName: 'supervised_user_circle', entity: 'employee' },
      { displayName: 'Project List', route: '/page/project', iconName: 'work_outline', entity: 'project' },
      { displayName: 'System User List', route: '/page/user', iconName: 'group_work', entity: 'user' },
      { displayName: 'Holiday List', route: '/page/holiday', iconName: 'calendar_today', entity: 'holiday' }
    ]
  },
  { displayName: 'Change Password', route: null, iconName: 'lock_open' },
  { displayName: 'Sign-out', route: null, iconName: 'logout' }
]