import { ReportService } from '../../../services/report.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { HttpService } from 'src/app/services/http.service';
import { LanguageService } from 'src/app/services/language.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-employee-dashboard.',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.scss']
})
export class EmployeeDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;
  firstDay: any;
  employeeData: any;
  employeeDetails: any;
  heading: any;
  groupByCol:any;
  employeeId: any;
  reqSubscription = [];
  pageTitle: any;
  page:any;
  pageData:any
  date: Date = new Date();
  selectedOrgId: any;


  constructor(public languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private authService: AuthService,
    public helperService: HelperService,
    private dataService: DataService
    ) {
    //this.dataService.getEmployeeList();
    this.firstDay = moment().startOf('month')
    this.reqSubscription.push(this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    }));
    this.reqSubscription.push(this.helperService.employeeDataSubject.subscribe((res) => {
      this.getEmployeeDetails();
    }))
    this.authService.selectedOrgId.subscribe(id=>{
      this.selectedOrgId = id
    })
  }

  ngOnInit() {
    this.getEmployeeDetails();
   }

  getEmployeeDetails() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.employeeId = params.get('id');
    })
    if (this.employeeId) {
      this.getEmployeeData();
    }
  }

  getEmployeeData() {

    this.reqSubscription.push(
      this.http.getDocById('employee', this.employeeId).subscribe((res: any) => {
        if (res) {
          this.employeeDetails = res;
          this.helperService.employeeEmitter(this.employeeDetails);
        }
      })
    )
  }

  /** Open left slider grid */
  openSliderPopup(value) {
    this.heading = []
    if(value == 'Attendance') {
      this.pageTitle = 'Attendance List';
      this.page = 'attendance';
      this.dataService.getEmployeeAttendanceList(this.firstDay.format(), moment(new Date()).endOf('day').format(), [this.employeeId]).subscribe(res=>{
        this.pageData = res
      })
    } else if(value == 'Leave') {
      this.pageTitle = 'Leave List';
      this.page = 'leave';
      this.dataService.getLeaveList(this.firstDay.format(), moment(new Date()).endOf('day').format(), this.employeeId).subscribe(res=>{
        this.pageData = res
      })
    } else if (value =='AddPing'){
      this.pageTitle ='Add Live Location'
      this.page = 'attendance';
      this.dataService.getEmployeeAttendanceList(this.firstDay.format(), moment(new Date()).endOf('day').format(), [this.employeeId]).subscribe(res=>{
        this.pageData = res
      })
    }else if (value =='removePing'){
      this.pageTitle ='Remove Live Location'
      this.page = 'attendance';
      this.dataService.getEmployeeAttendanceList(this.firstDay.format(), moment(new Date()).endOf('day').format(), [this.employeeId]).subscribe(res=>{
        this.pageData = res
      })
    }
    this.sidenav.open();
  }

  ngOnDestroy() {
    this.reqSubscription.forEach(sub => sub.unsubscribe())
  }
}
