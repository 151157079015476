import { HelperService } from 'src/app/services/helper.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  isOpened = false;
  loggedIn = true;
  myControl = new FormControl();
  env: any = environment;
  logo:any
  options = [];
  filteredOptions = [];
  searchForm: FormGroup;
  filter: any;
  visible = false;
  public orgName:string
  userData:any
  orgList:any
  selectedOrgId: string = localStorage.getItem("selectedOrgId")
  constructor(
    private router: Router,
    public languageService: LanguageService,
    private authService : AuthService,
    private httpService: HttpService,
    private helperService:HelperService)
    {
      this.searchForm = new FormGroup({
        searchKey: new FormControl()
      })
      this.helperService.matSubject.subscribe(res => {
        this.isOpened = res;
      })
      this.userData = this.authService.userAuthData;
  }

  ngOnInit(): void {
    this.orgName = this.userData?.org["app_name"]
    this.logo = this.helperService.getAppLogo()
    if (this.userData?.org["type"]=='CO') {
      this.httpService.getDocList('organization').subscribe((result:[]) =>{
        this.orgList = result.filter(o=>o["type"]=="PO" && o["group"] == this.userData?.org["group"])
      })
    }
   }

   setSelectedOrgId(org) {
     this.authService.setSelectedOrgId(org.value)
   }

  toggleNav() {
    this.isOpened = !this.isOpened
    this.helperService.menuEmitter(this.isOpened);
  }

  onSelectionChange(event) {
    const id = event.option.value;
    this.router.navigate(['employee/' + id]);
    this.myControl.reset();
  }
}
