import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormioComponent, FormioRefreshValue } from '@formio/angular';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Location } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'dynamic-form',
  templateUrl: './form-add-edit.component.html',
  styleUrls: ['./form-add-edit.component.scss'],
})
export class FormAddEditComponent implements OnInit {
  @ViewChild('json', { static: true }) jsonElement?: ElementRef;
  @ViewChild('code', { static: true }) codeElement?: ElementRef;
  @ViewChild(FormioComponent, { static: false })
  formioComponent?: FormioComponent;

  @Input('formname') formname: string;
  @Input('hideSendButton') hideSendButton: any;
  @Input('docid') docid: string;
  @Input('empid') empid: string;
  @Input('attendanceInfo') attendanceInfo: any;
  @Input('showtitle') showtitle: boolean;
  @Input('showcancel') showcancel: boolean;
  // @Input('model') model:any = null

  public form: any;
  public submission: any;
  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();

  isEdit: boolean = false;
  pageTitle: any;
  data: any;
  formData: any;
  showTitle: boolean = true;
  showCancel: boolean = true;
  reqSubscription: Subscription[] = [];
  visible: boolean = false;
  visibleData: boolean = false;
  formName: string;
  id: string;
  eid: string;
  type: any;

  constructor(
    private http: HttpService,
    private snackbar: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private helperService: HelperService,
    private dialogService: DialogService,
    private cf: ChangeDetectorRef,
    private sharedService: SharedService
  ) {
    this.form = { components: [] };
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('page')) {
        this.formName = params.get('page');
        this.id = params.get('id');
        this.eid = params.get('eid');
        this.type = params.get('type');
        this.isEdit = this.id ? true : false;
        this.pageTitle = !this.isEdit
          ? 'Add ' + this.helperService.capitalize(this.formName)
          : '';
      } else {
        this.formName = this.formname;
        this.eid = this.empid;
        this.id = this.docid;
        this.showTitle = this.showtitle;
        this.showCancel = this.showcancel;
      }
      //  else if(this.formName == 'attendance' && this.model){
      //   this.isEdit = true;
      // }
    });
    this.loadDetails();
  }

  loadDetails() {
    if (this.formName) {
      // if (){

      // }
      this.helperService
        .getJsonData(`../../../../../assets/json/${this.formName}.json`)
        .then((res) => {
          this.form = res;

          if (this.formName == 'notification') {
            this.submission = { data: { eid: this.eid } };
          }

          for (const control of this.form?.components) {
            let locality = this.attendanceInfo?.['loc']?.['locality'];
            let subLocality = this.attendanceInfo?.['loc']?.['subLocality'];
            let street = this.attendanceInfo?.['loc']?.['street'];
            let road = this.attendanceInfo?.['loc']?.['road'];

            if (control.key == 'longitude') {
              control.defaultValue = this.attendanceInfo['lng'];
            } else if (control.key == 'latitude') {
              control.defaultValue = this.attendanceInfo['lat'];
            } else if (control.key == 'loc') {
              control.defaultValue =
                (this.checkUndefind(locality)
                  ? this.checkUndefind(subLocality)
                    ? ''
                    : subLocality
                  : locality) +
                (this.checkUndefind(street)
                  ? this.checkUndefind(road)
                    ? ''
                    : '/' + road
                  : '/' + street);
            }
            var d = this.isEdit ? control.isEdit : control.isAdd;
            if (d) {
              control.disabled = d.disabled;
              control.hidden = d.hidden;
            }
          }
        });
    }

    if (this.isEdit && this.formName != 'attendance') {
      this.http
        .getDocById(this.formName.toLowerCase(), this.id)
        .subscribe((res: any) => {
          if (res) {
            this.data = res;

            this.submission = { data: this.data };
            if (this.formName == 'holiday') {
              this.pageTitle = 'Edit ' + this.data.desc;
            } else {
              this.pageTitle = 'Edit ' + this.data.name;
            }
          }
        });
    }

    this.refreshForm.emit({
      property: 'form',
      value: this.form,
    });
    this.visible = true;
  }

  checkUndefind(data) {
    if (data === undefined) {
      return true;
    }
    return false;
  }

  getData(data) {
    this.formData = data;
    this.helperService.formEmitter(this.formData);
  }

  // onChange(event) {
  //    ;
  //   this.jsonElement.nativeElement.innerHTML = '';
  //   this.jsonElement.nativeElement.appendChild(
  //     document.createTextNode(JSON.stringify(event.form, null, 4))
  //   );
  //   this.refreshForm.emit({
  //     property: 'form',
  //     value: event.form,
  //   });
  // }

  ngOnDestroy() {
    this.reqSubscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  cancel() {
    console.log('cancel');
    if (this.type == 'profile') {
      this.location.back();
    } else {
      this.router.navigate([
        `/${this.formName != 'attendance' ? 'page' : 'list'}/${this.formName}`,
      ]);
    }
  }
  // hideSendButton = false
  // hideSubmitButton = false
  onSubmit(data) {
    this.formioComponent.formio.executeSubmit();
    if (this.formioComponent.formio.isValid()) {
      var profilePhoto: any;
      this.reqSubscription.push(
        this.helperService.formSubject.subscribe((formData: any) => {
          if (formData) {
            if (!formData['status']) formData['status'] = 'A';
            if (this.formData.mobile) {
              this.formData.mobile = this.formData.mobile + '';
            }
            if (
              !this.isEdit &&
              this.formData.photo_url &&
              this.formData.photo_url.length
            ) {
              profilePhoto = {
                emp_id: '',
                data: this.formData.photo_url[0].url,
              };
            }
            if (this.formName == 'attendance') {
              this.hideSendButton = false;
              // this.hideSendButton=true
              this.formData.latitude = this.attendanceInfo['lat'];
              this.formData.longitude = this.attendanceInfo['lng'];
              this.formData.action = this.attendanceInfo['type'];
              this.formData.emp_id = this.attendanceInfo['emp_id'];
              this.formData.location = this.attendanceInfo['loc'];
              this.formData.is_sign_in = false;
              //
              if (this.formData.action === 'in') {
                this.formData.is_sign_in = true;
              }

              this.formData.Device_Id;
              {
                this.http.addAttendance(this.formData).subscribe((res: any) => {
                 
                  if (res.error_msg){
                        this.snackbar.snackbarError(res.error_msg, 'center');
                    // this.dialogService.closeModal();
                    return
                  }
                  if (res) { 
                    sessionStorage.setItem('in', JSON.stringify(this.formData)); 
                    
                    this.dialogService.closeModal();
                    // this.isEdit = false;
                    // this.model = null;
                    this.callMethodInFormlyFieldSelectDynamicOptions(true)
                    this.snackbar.snackbarSuccess(
                      `${this.helperService.capitalize(
                        this.formName
                      )} data has been added successfully`
                    );
                    // this.router.navigate([`/list/${this.formName}`])
                  }
                });
                // localStorage.removeItem("device_id")
              }
            } else if (this.formName == 'ping') {
              // this.hideSubmitButton=true
              debugger
              this.hideSendButton = true;
              this.formData.latitude = this.attendanceInfo['lat'];
              this.formData.longitude = this.attendanceInfo['lng'];
              this.formData.action = this.attendanceInfo['type'];
              this.formData.emp_id = this.attendanceInfo['emp_id'];

              // const Device_Id = localStorage.getItem('Device_Id');
              // this.formData.Device_Id = Device_Id.replace(/[\[\]"]/g, '');
              const org_id = localStorage.getItem('selectedOrgId');
              this.formData.org_id = org_id.replace(/[\[\]"]/g, '');
              const _id = localStorage.getItem('_id');

              if (_id == null) {
                let dt: any = JSON.parse(sessionStorage.getItem('in')); 
                 this.formData.dt = dt.date
              }
              this.formData._id = _id?.replace(/[\[\]"]/g, '');

              // }
              this.http.addping(this.formData).subscribe((res: any) => {
                // this.http.addAttendance(this.formData).subscribe((res: any) => {
                // api 
                if (res) { 
                  this.snackbar.snackbarError(res.error_msg, 'center');
                  // this.dialogService.closeModal();
                    
                }else{
                  // this.isEdit = false;
                  // this.model = null;
                  this.dialogService.closeModal(); 
                  this.callMethodInFormlyFieldSelectDynamicOptions(false)
                  this.snackbar.snackbarSuccess(
                    `${this.helperService.capitalize(
                      this.formName
                    )} data has been added successfully`
                  );
                }
              

                // this.router.navigate([`/list/${this.formName}`])
                // }
                // sessionStorage.removeItem("in")
              });

              // }
            } else if (this.isEdit) {
              console.log('edit', formData);
              delete formData['_id'];
              this.http
                .updateDoc(this.formName.toLowerCase(), this.data._id, formData)
                .subscribe((res: any) => {
                  if (res) {
                    this.snackbar.snackbarSuccess(
                      `${this.helperService.capitalize(
                        this.formName
                      )} data has been updated successfully`
                    );
                    this.router.navigate([
                      `/${this.formName != 'attendance' ? 'page' : 'list'}/${
                        this.formName
                      }`,
                    ]);
                  }
                });
            } else {
              if (this.formName == 'employee') {
                formData['_id'] = formData.code;
              }
              if (this.formName === 'leave') {
                this.http
                  .getDocById('employee', formData.eid)
                  .subscribe((res: any) => {
                    formData['name'] = res.name;
                  });
              }
              this.http
                .addDoc(this.formName.toLowerCase(), formData)
                .subscribe((res: any) => {
                  if (res) {
                    if (this.formName.toLowerCase() == 'notification') {
                      this.snackbar.snackbarSuccess(
                        `Notification has been sent successfully`
                      );
                    } else {
                      if (profilePhoto) {
                        profilePhoto.emp_id = res.InsertedID;
                        this.helperService.updateProfilePicture(profilePhoto);
                      }
                      this.snackbar.snackbarSuccess(
                        `${this.helperService.capitalize(
                          this.formName
                        )} data has been added successfully`
                      );
                      this.router.navigate([
                        `/${this.formName != 'attendance' ? 'page' : 'list'}/${
                          this.formName
                        }`,
                      ]);
                    }
                  }
                });
            }
          }
          this.cf.detectChanges();
        })
      );
    }
  }

  callMethodInFormlyFieldSelectDynamicOptions(data: any) {
    this.sharedService.triggergeoLocationChange(data);
  }  

}
