import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
var API_BASE_URL = environment.apiBaseUrl;
var TRACKING_API_URL = environment.trackingBaseUrl;
@Injectable()
export class HttpService {
    headers = new HttpHeaders();
    constructor(private http: HttpClient) { }
    getDocList(entityName) {
        return this.http.get(`${API_BASE_URL}entities/${entityName}`)
    }

    getDocListBySearch(entityName,data) {
        return this.http.post(`${API_BASE_URL}entities/search/${entityName}`,data)
    }

    getDocById(entityName,id) {

        return this.http.get(`${API_BASE_URL}entities/${entityName}/${id}`)
    }

    addDoc(entityName,data) {

        return this.http.post(`${API_BASE_URL}entities/${entityName}`,data)
    }

    updateDoc(entityName,id,data) {
        return this.http.put(`${API_BASE_URL}entities/${entityName}/${id}`,data)
    }

    login(body) {

        return this.http.post(`${API_BASE_URL}auth/login`, body);
    }

    attendanceList(body) {
        return this.http.post(`${TRACKING_API_URL}report/day-attendance`, body);
    }

    addAttendance(body) {
        //  ;
        //return this.http.post(`${TRACKING_API_URL}loc`, body)
        return this.http.post(`${API_BASE_URL}attendance/update`, body)
    }
    
    
    addping(body){
        // console.log(body);
        // return this.http.post(`${TRACKING_API_URL}loc`, body)
        return this.http.post(`${TRACKING_API_URL}loc`, body)
    }
	//  r.HandleFunc("/deletePing/{id}/{collectionName}/{actionType}", DevicePing).Methods(http.MethodPost) ///:id/:index
    
    removeping(data:any,index :any,collectionName:any,actionType:any){
        return this.http.post(`${TRACKING_API_URL}device/deletePing/${index}/${collectionName}/${actionType}`, data)
    }
    updatePing(body:any,collectionName){
        return this.http.post(`${TRACKING_API_URL}device/updatePing/${collectionName}`, body)
    }
 



    subscriptionKey() {
        return this.http.get(`${TRACKING_API_URL}subscription-key`)
    }

    leaveReport(body) {
        return this.http.post(`${API_BASE_URL}report/leave`, body)
    }

    profilePhotoUpdate(body) {
        return this.http.post(`${API_BASE_URL}entities/profile/photo`, body)
    }

    resetPassword(body) {
        return this.http.post(`${API_BASE_URL}auth/reset-password`, body);
    }

    getConfig() {
        return this.http.get(`${API_BASE_URL}auth/config`)
    }

    getLocationInfo(lat: number, lng: number): Observable<any> {
        const url = `${API_BASE_URL}reverse-geocode/${lat}/${lng}`;
        return this.http.get<any>(url);
      }
    
      
    getPingDetails(){
          return this.http.get(`${API_BASE_URL}auth/config`)
    }
  

    
    empAttendance(orgId, empIds, startTime, endTime) {
        return this.http.get(`${API_BASE_URL}/AttendanceUpdate/${orgId}/${empIds}/${startTime}/${endTime}`);
      }

    removemarker(orgId, empIds, startTime, endTime) {
        return this.http.delete(`${API_BASE_URL}/removemarker/${orgId}/${empIds}/${startTime}/${endTime}`);
      }

      getAllEmployees(): Observable<any> {
        return this.http.get(`${API_BASE_URL}/getallempolyee`);
      }

      getEmp_Attendance(data:any){ 
        return this.http.post(`${API_BASE_URL}attendance/emp_attendance`,data) 
      }
}