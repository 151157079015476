import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class SharedService {
    private geolocationDatafield = new BehaviorSubject<any>(null);
    geolocationDatafield$ = this.geolocationDatafield.asObservable();
  
    triggergeoLocationChange(data: any) {
      this.geolocationDatafield.next(data);
    }
  }