<div>
  <div fxflex fxLayout="column" fxLayoutAlign="center center" class="profile">
    <img [src]="helperService.getProfileImage(employeeDetail?.photo_url)"
      class="profileSize" *ngIf="employeeDetail">
      <input 
        hidden 
        type="file" 
        #uploader
        (change)="uploadFile($event)"
    />
    <div class="edit-icon">
      <mat-icon class="edit" style="cursor: pointer" (click)="uploader.click()">camera_enhance</mat-icon>
    </div>
    <!-- <mat-icon class="backIcon" (click)="goBack()">arrow_back</mat-icon> -->
    <br>
  </div>
  <div fxflex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
    <p class="mtb-10"><b>{{employeeDetail?.name}}</b> </p>
    <p class="mtb-10"><b>({{employeeDetail?._id}})</b> </p>
    <p class="mtb-10" style="justify-content: center;text-align: center;" *ngIf="employeeDetail?.designation">{{employeeDetail.designation}}</p>
    <p class="mtb-10"><b>{{employeeDetail?.pid}} Project</b> </p>
    <p></p>
    <!-- <p class="mtb-10" *ngIf="employeeAge">Age {{employeeAge}}</p> -->
    <p *ngIf="employeeSince" class="mtb-10">Employee Since {{employeeSince | date:'yyyy'}}</p>
    <p class="mtb-10" *ngIf="employeeDetail?.mobile">
      <mat-icon class="memIcon">call</mat-icon>
      {{employeeDetail.mobile}}
    </p>
    <p class="mtb-10" *ngIf="employeeDetail?.email">
      <mat-icon class="memIcon">email</mat-icon>{{employeeDetail.email}}
    </p>
    <button class="mtbb-10 width-80 submit_but" *ngIf="visibleEdit"  mat-raised-button (click)="editProfile(employeeDetail)">
      <mat-icon class="memIcon">edit</mat-icon>Edit Profile
    </button>
    <button class="mtbb-10 width-80 submit_but" mat-raised-button (click)="viewAttendance()">
      <mat-icon class="memIcon">calendar_today</mat-icon>View Attendance
    </button>
    <button class="mtbb-10 width-80 submit_but" mat-raised-button (click)="viewLeave()">
      <mat-icon class="memIcon">insert_invitation</mat-icon>View Leave
    </button>
    <button class="mtbb-10 width-80 submit_but" mat-raised-button (click)="openNotificationDialog(employeeDetail)">
      <mat-icon class="memIcon">notification_important</mat-icon>Send Notification
    </button>
    <button class="mtbb-10 width-80 submit_but" mat-raised-button (click)="addlivePing()" *ngIf="userDetailsService?true:false">
      <mat-icon class="memIcon">calendar_today</mat-icon>Add Live Location
    </button>
   
    <button class="mtbb-10 width-80 submit_but" mat-raised-button (click)="removePing()" *ngIf="userDetailsService?true:false">
      <mat-icon class="memIcon">calendar_today</mat-icon>Delete Live Location
    </button>
    
    <p *ngIf="employeeDetail?.current_address?.street" class="mtb-10">Door No: {{employeeDetail.current_address.street}}
    </p>
    <p *ngIf="employeeDetail?.current_address?.area" class="mtb-10">{{employeeDetail.current_address.area}}</p>
    <p *ngIf="employeeDetail?.current_address?.city" class="mtb-10">
      {{employeeDetail.current_address.city}}
    </p>
    <p *ngIf="employeeDetail?.current_address?.state" class="mtb-10">{{employeeDetail.current_address.state}} -
      {{employeeDetail.current_address.postal_code}}</p>
  </div>

  <ng-template #popupDialog let-data>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle>Notification to {{data.name}}, {{data.designation}}</h2>
    <mat-dialog-content>
        <dynamic-form [formname]="editForm" [empid]="data._id" [id]="data._id"></dynamic-form>
    </mat-dialog-content>
  </ng-template>
