import { trigger, transition, style, animate } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { defaultMenu } from 'src/app/configs/menu';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-body',
  templateUrl: './app-body.component.html',
  styleUrls: ['./app-body.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class AppBodyComponent implements OnInit {

  userDisplayName = 'Admin';
  navMenu: any;
  user_type = '';
  loggedIn: boolean = false;
  isExpanded: boolean = true;
  isOpened: boolean = false;
  showReportsSubMenu: boolean = false;
  showReportsSecondMenu: boolean = false;
  env: any = environment;
  logo = this.env.logoUrl;

  mobileQuery: MediaQueryList;
  mobileQueriesFunction: any;
  mobileQueries: MediaQueryList;


  private _mobileQueryListener: () => void;
  userData: any;

  dialogRef: any;
  image: any;
  userName: string;
  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public dialog: MatDialog,
    public languageService: LanguageService,
    private helperService: HelperService
  ) {

    var userDetails = JSON.parse(localStorage.getItem('lts_auth'));
    
    
    this.navMenu = defaultMenu.filter(x =>((userDetails.org?.type == 'PO' && userDetails.role > 1) ?x.displayName!='Change Password': (x.displayName!='Master Data')&&(x.displayName!='Change Password')));
    this.helperService.matSubject.subscribe(res => {
      var d = JSON.parse(localStorage.getItem('lts_auth'));
      this.image = d?.photo_url ? d.photo_url : 'assets/avatar.png';
      this.userName = d?.name;
      this.isOpened = res;
    })
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.mobileQueries = media.matchMedia('(max-width: 800px)');
    this.mobileQueriesFunction = (x: any) => { this.isExpanded = x.matches ? false : true; };
    this.mobileQueries.addListener(this.mobileQueriesFunction);
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.setLoggedIn();
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  ngOnInit() {
  }

  /**Check whether application is logged in */
  public setLoggedIn() {
    var log = localStorage.getItem('isLoggedIn')
    const isLoggedIn = log ? true : false;
    if (!this.loggedIn && isLoggedIn) {
    }
    if (!isLoggedIn) {
    }
    return this.loggedIn = isLoggedIn;
  }

  /**toggle side nav slide in and out */
  toggleNav() {
    this.isOpened = !this.isOpened
    this.helperService.menuEmitter(this.isOpened);
  }

  toggleNavIcon() {
    if (this.mobileQuery) {
      this.isOpened = true;
      this.isExpanded = !this.isExpanded;
    }
  }
}
