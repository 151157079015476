import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from 'angular-reactive-validation';
import { Subject, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe, PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import * as config from '../../assets/grid-filter/config.json'

@Injectable({
  providedIn: 'root'
})
export class HelperService implements OnInit {
  public loggedUser: any
  public loggedUserId!: String
  public loggedUserToken!: string
  private _config: any

  private subject = new Subject<any>();
  matSubject: Subject<any> = new Subject<any>();
  tableSubject: Subject<any> = new Subject<any>();
  employeeSubject: Subject<any> = new Subject<any>();
  employeeDataSubject: Subject<any> = new Subject<any>();
  userData: any
  formSubject: Subject<any> = new Subject<any>();
  selectedOrgId: Subject<string> = new Subject<string>();
  refreshSelectedList: Subject<any> = new Subject<any>();

  // public getPartnerType : Subject<any> = new BehaviorSubject<any>({});
  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    public loc: PlatformLocation,
    private bpo: BreakpointObserver, //Layout size identification
    private datepipe: DatePipe,
    private router: Router
  ) {
    this._config = config;
    this.selectedOrgId.asObservable()
  }

  public ngOnInit() {
  }

  set Config(val) {
    this._config = val
  }

  get Config() {
    return this._config
  }

  public isEmpty = (data: string) => {
    if (data === "") return true;
    return false;
  }

  public ImagetoDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  //Baskar
  getSignInLogo() {
    var hostName = this.loc.hostname.replace("www.", "").split(".")
    if (hostName.length >= 3) { //Request from subdomains
      return `assets/logo/${hostName[0]}_logo.png`
    }
    return "assets/logo/kt_logo.png"
  }

  getOrgId() {
    var hostName = this.loc.hostname.replace("www.", "").split(".")
    if (hostName.length == 3) { //Request from specific sub domain
      return hostName[0]
    }
    return "kt"
  }

  getAppLogo() {
    var hostName = this.loc.hostname.split(".")
    if (hostName.length >= 3) { //Request from subdomains
      return `assets/logo/${hostName[0]}_app_logo.png`
    }
    return `assets/logo/kt_logo.png`
  }

  getCustomerLogo(selectedOrgId) {
    if (!selectedOrgId || selectedOrgId == "") return this.getSignInLogo()
    // return `assets/logo/${selectedOrgId.split('_')[0]}_logo.png`
    return `assets/report-logo/${selectedOrgId.split('_')[0]}_logo.png`
  }
  getProfileImage(filePath: string) {
    if (filePath) {
      return environment.staticFileBaseUrl + filePath
    }
    return 'assets/avatar.png'
  }

  showDate(dt: any) {
    if (!dt) return ""
    try {
      return this.datepipe.transform(dt, 'dd-MM-yy')
    } catch (e) {
      return ""
    }
  }

  showDateTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datepipe.transform(dt, 'dd-MM-yy hh:mm a')
    } catch (e) {
      return ""
    }
  }

  showFullDate(dt: any) {
    if (!dt) return ""
    try {
      return this.datepipe.transform(dt, 'EEE, MMM d, y')
    } catch (e) {
      return ""
    }
  }

  showFullDateTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datepipe.transform(dt, 'EEE, MMM d, y, hh:mm a')
    } catch (e) {
      return ""
    }
  }

  showTime(dt: any) {
    if (!dt) return ""
    try {
      return this.datepipe.transform(dt, 'hh:mm a')
    } catch (e) {
      return ""
    }
  }

  getJsonData = async (fileNameWithPath: string) => {
    var data: any = [];
    var fileName = fileNameWithPath.replace(/^.*[\\\/]/, '');
    fileName = fileName.substring(0, fileName.lastIndexOf('.'));
    //check whether the file available in local storage or not
    try {
      return await this.httpClient.get(fileNameWithPath).toPromise().catch((e)=>{
        return null;
      }).then(res=>{
        return res
      })
    } catch (e) {
      return null
    }
  }

  getSelectedOrgId() {
    return localStorage.getItem("selectedOrgId")
  }

  navigateToEmployeeDashboard(id) {
    if (id) this.router.navigate(['employee/' + id])
  }

  /**
   * To Create TextBox Control
   * @param controlName
   * @param defaultValue
   * @param required
   * @param minLength
   * @param maxLength
   * @param pattern
   * @param type
   */

  createTextControl(controlName: string, defaultValue?: any, required?: boolean, minLength?: number, maxLength?: number, pattern?: string) {
    var vArray = []
    if (required) {
      vArray.push(Validators.required(`${controlName} is required`))
    }
    if (minLength) vArray.push(Validators.minLength(minLength, min => `${controlName}  should be minimum ${min} character length`))
    if (maxLength) vArray.push(Validators.maxLength(maxLength, max => `${controlName}  should be maximum ${max} character length`))
    if (pattern) vArray.push(Validators.pattern(pattern, 'Invalid format'))
    return new FormControl(defaultValue, vArray)
  }


  /**
   * To Create Email Control
   * @param controlName
   * @param defaultValuy
   * @param required
   */
  createEmailControl(controlName: string, defaultValue?: any, required?: boolean) {
    var vArray = []
    if (required) vArray.push(Validators.required(`${controlName} is required`))
    vArray.push(Validators.email('Invalid Email format'))
    return new FormControl(defaultValue, vArray)
  }

  /**
   *
   * This method will dynamically collect the all the form fields and apply the validation rules
   */
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  getImage(filename: any) {
    if (filename && filename != null && filename.indexOf('http') > -1) {
      return filename
    } else {
      //default image
      return 'http://' + filename
    }
  }

  formatData(data, type) {
    if (!data) return ''
    if (type == "date") {
      return this.showDate(data)
    } else if (type == "time") {
      return this.showTime(data)
    } else if (type == "dateTime") {
      return this.showDateTime(data)
    } else if (type == "duration") {
      return this.convertMinsToHrsMins(data)
    }
    return data
  }


  getDataFromRow(row, col: any, type, prefix?:any,suffix?:any) {
    if (!row) return ""
     if (col instanceof Array) {
        let result = ""
        for(let idx=0;idx<col.length;idx++) {
             result += this.getDataFromRow(row,col[idx],col[idx].type || type ,prefix,suffix)
        }
        return result;
     } else {
      if (col instanceof Object) {
         return  this.getDataFromRow(row,col.name,col.type || type,col.prefix,col.suffix)
      } else {
        var dot = col.indexOf('.')
        if (dot > 0) {
          if (row[col.substr(0, dot)])
            return this.getDataFromRow(row[col.substr(0, dot)], col.substr(dot + 1), type,prefix,suffix)
        }
        if (row) {
          let val = this.formatData(row[col], type)
          return  val!=""? (prefix || '') + val + (suffix || ''):""
        }
    }
    }
    return ""
  }



  convertMinsToHrsMins(minutes: any) {
    let hours, mins;
    var h = Math.floor(minutes / 60);
    var m = Math.round(minutes % 60);
    hours = h < 10 ? '0' + h : h;
    mins = m < 10 ? '0' + m : m;
    return hours + ':' + mins;
  }

  endAfterStart(start: any, end: any) {
    var startDate = new Date(start);
    var endDate = new Date(end);
    return endDate.getTime() == startDate.getTime();
  }

  updateProfilePicture(body, isUpdate?) {
    if (body) {
      this.httpService.profilePhotoUpdate(body).subscribe((r: any) => {
        console.log('profile photo updated')
        if(isUpdate == 'update') {
          this.employeeDataSubject.next(true)
        }
      })
    }
  }

  getThemeData() {
    var theme = JSON.parse(localStorage.getItem('theme'));
    if(!theme) {
      this.loadTheme()
    } else {
      this.setThemeData(theme)
    }
  }

  loadTheme() {
    this.httpService.getConfig().subscribe((res: any) => {
      if(res) {
        var data = res?.data?.style;
        this.setThemeData(data);
        localStorage.setItem('theme', JSON.stringify(data))
      } else {
        // default value when no style is available
        var d = this._config.default.defaultTheme;
        localStorage.setItem('theme', JSON.stringify(d))
        this.setThemeData(d)
      }
    })
  }


  setThemeData(data) {
    var d: string = "";
    data.forEach((obj: any) => {
      d = d + `--${obj.Key}: ${obj.Value};`;
    });
    document.querySelector("body").style.cssText = d;
  }

  userDataEmitter(data: any) {
    if (data) {
      this.selectedOrgId.next(data["org_id"])
      this.userData = data
    }
  }

  getUpdatedSelectedList(data: any) {
    this.refreshSelectedList.next(data)
  }

  changeOrg(OrgId: string) {
    this.selectedOrgId.next(OrgId)
  }

  calculateAge(dob: any) {
    if (dob) {
      const diffMs = Date.now() - new Date(dob).getTime();
      const ageDt = new Date(diffMs);
      let age = Math.abs(ageDt.getUTCFullYear() - 1970);
      if (!isNaN(age)) {
        return age;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  formEmitter(data: any) {
    this.formSubject.next(data);
  }
  menuEmitter(data: any) {
    this.matSubject.next(data);
  }
  tableEmitter(data: any) {
    this.tableSubject.next(data);
  }
  employeeEmitter(data: any) {
    this.employeeSubject.next(data);
  }
  // employeeDataEmitter() {
  //   console.log("emit")
  //   this.employeeDataSubject.next();
  // }
  sendFormName(value: any) {
    this.subject.next({ content: value });
  }

  getFormName(): Observable<any> {
    return this.subject.asObservable();
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
