import { AuthService } from 'src/app/services/auth.service';
import { CsvExportService } from 'src/app/services/csvexport.service';
import { PdfService } from 'src/app/services/pdf.service';
import { HelperService } from 'src/app/services/helper.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SnackbarService } from './snackbar/snackbar.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})

export class ReportService {
  leftLogo:any
  rightLogo:any
  orgName:any
  org = JSON.parse(localStorage.getItem('lts_auth')).org
  selectedOrgId = localStorage.getItem('selectedOrgId')
  constructor(
    private helperService : HelperService,
    private pdfService : PdfService,
    private csvService: CsvExportService,
    private snackbar: SnackbarService,
    private authService : AuthService
  ) {
    this.orgName = this.authService.userAuthData?.org["name"]
    this.authService.selectedOrgId.subscribe(id=>{
      this.selectedOrgId = id
    })
  }

async generatePdf(reportName,data,groupBy,titles?) {
 
    let orgcode = this.selectedOrgId.split("_")[0]
    var config =  await this.helperService.getJsonData(`assets/report-config/${reportName}_${orgcode}.json`)
    if (config == null) {
      config =  await this.helperService.getJsonData(`assets/report-config/${reportName}.json`) 
    }
    if (config == null) {
        this.snackbar.snackbarSuccess('Sorry! Something went wrong');
        return
    }
    this.leftLogo = await this.getImage(this.helperService.getCustomerLogo(this.selectedOrgId))
    this.rightLogo = await this.getImage('assets/logo/report_right.png')
    var content = this.loadContent(config, data, groupBy, titles)
    var reportTitle = `${this.orgName}_${config["fileName"]}`
    this.pdfService.generatePdf(this.orgName,content,reportTitle, config["orientation"] || 'portrait')
}

async generateCSV(reportName,data) {
   
    var config =  await this.helperService.getJsonData(`assets/report-config/${reportName}.json`) 
    if (this.authService.userAuthData?.org["loc"]) {
        let orgcode = this.selectedOrgId.split("_")[0]
        config =  await this.helperService.getJsonData(`assets/report-config/${reportName}_${orgcode}.json`)
    } 
    if (config == null) {
      this.snackbar.snackbarSuccess('Sorry! Something went wrong');
      return
    }
    this.csvService.downloadFile(
      data,
      config["csvColumns"],
      config["csvColHeader"],
      config["fileName"]
      )
}

private loadContent(config,data,groupBy,titles?) {
 
  var content =[]
  if (!data.length) { // No Records
    content.push({ text: "No Record Found"});
    return content
  }

  if (!groupBy || groupBy == null ) groupBy = config["defaultGroupBy"] || null
  if (groupBy!=null) {
     //get group by column def
     var grpCol = config["groupCols"]? config["groupCols"][groupBy]: null
      // get group by function
      var groupField = item => item[groupBy]
      if (grpCol.type == "date") {
          //group by specific field with required format
          groupField = item => moment(item[groupBy]).format(grpCol.format);
      }
  }

  //get group result
  const dataGrpResult:any = _.groupBy(data,groupField)
  // Generate data table based on group field
  var tableCount=1
  var dataObject =  Object.keys(dataGrpResult)
  dataObject.forEach((key:any)=> {
      content.push(this.getPageData(config,dataGrpResult,key,grpCol,titles))
      tableCount++
      if (tableCount <= dataObject.length)
        //Page Break after group data
        content.push({ text: "", pageBreak: 'after' });
  })
  return content
}

//This getPageData method contains header and as well table body
private getPageData(config,one,key,grpCol,titles) {
  
  // one[key].map((d)=>{
  //   console.log('one'+d['sign_in']['loc']['locality'])
  //   if (d['sign_in']['loc']['locality']===undefined) {
     
  //     d['sign_in']['loc']['locality']=d['sign_in']['loc']['subLocality']
  //   }
  //   console.log('two'+d['sign_in']['loc']['street'])
  //   if (d['sign_in']['loc']['street']===undefined){
  //     console.log('two'+d['sign_in']['loc']['street'])
  //     d['sign_in']['loc']['street']=d['sign_in']['loc']['road']
  //   }
  //   return d
  // })

  var tabledata = []
  var idx=1
  var headCols = config["pdfColHeader"]
  var widths = config["pdfColwidth"]
  var dataCols = config["pdfColumns"]
  //page header 
  
  var pageHead = this.getPageHeader(config,one[key][0],key,grpCol,titles)
  tabledata.push(pageHead)
  //push table header
  tabledata.push(this.getTableHeader(headCols))

  //generate table rows
  one[key].forEach((doc: any) => {
    var val = this.getColVals(dataCols,doc,idx++)
    tabledata.push(val);
  });

 
  return {
    table: {
      widths: widths,
      headerRows: 2,
      body:tabledata
    }
    }
}
private widthSetImagePdf(){
  let object:any ={
    width:130,
    margin:[0, 20, 0, 0]
  }

  if (this.selectedOrgId.split('_')[0] === 'vb'){
    object.width = 50
    object.margin = [0, 0, 0, 0]
     return object
    
  }

  return object
}

private twoColumnHeader(headTextMargin,subTitles,colCount) { 

  return {
    table: {
      widths: ['*', 200, '*'], 
      body: [
        [
          {
            image: this.leftLogo,
            width:  this.widthSetImagePdf().width,
            margin: this.widthSetImagePdf().margin, 
            border: [false, false, false, false],
            alignment : 'left'
          },
          {
            alignment: 'center',
            border: [false, false, false, false],
            margin : headTextMargin,
            table: {
              widths: ['*'],
              body: subTitles,
            }
          }
        ]
      ]
    } , colSpan: colCount
    , border: [false, false, false, false]
  }
}

private threeColumnHeader(headTextMargin,subTitles,colCount) {
  return {
    table: {
      widths: ['*', 200, '*'],
      body: [
        [
          {
            image: this.leftLogo,
            width: 130,
            margin: [0, 20, 0, 0],
            border: [false, false, false, false],
            alignment : 'left'
          },
          {
            alignment: 'center',
            border: [false, false, false, false],
            margin : headTextMargin,
            table: {
              widths: ['*'],
              body: subTitles,
            }
          },
          {
            image: this.rightLogo,
            width: 50,
            margin: [0, 10, 0, 0],
            border: [false, false, false, false],
            alignment : 'right'
          }
        ]
      ]
    } , colSpan: colCount
    , border: [false, false, false, false]
  }
}


private getPageHeader(config,data,key,grpCol,titles?) {
    //header text top margin adjustment - 4 points per line
    var headTextMargin = (grpCol && grpCol.margin) ? grpCol.margin :[0,0,0,0]
    var colCount = config["pdfColHeader"].length
    var subTitles = [];
    subTitles.push(
      [{text: config['title']||'', style: 'title', bold: true, alignment: 'center', fontSize: '14', border: [false, false, false, false],}]
    )
    if (titles) {
      titles.forEach(element => {
        subTitles.push(
            [{ text: element, style: 'sub-title', alignment: 'center', fontSize: '9', border: [false, false, false, false]}]
          )
        });
    }
    if (grpCol!=null && grpCol.headFormat) {
        if (grpCol.type == "date") {
            subTitles.push(
                [{text: moment(key).format(grpCol.headFormat), style: 'title', bold: true, alignment: 'center', fontSize: '10', border: [false, false, false, false],}]
             )
        } else {
          if (typeof(grpCol.headFormat)=== 'string') {
              subTitles.push(
                [{text: eval(grpCol.headFormat), style: 'title', bold: true, alignment: 'center', fontSize: '10', border: [false, false, false, false],}]
            )
          } else { //array of heading
            grpCol.headFormat.forEach(e => {
              subTitles.push(
                [{text: eval(e), style: 'title', bold: true, alignment: 'center', fontSize: '10', border: [false, false, false, false],}]
            )
            });
          }
        }
    }
    var heading = []
    //Actual Heading
    if (this.org.group != 'ckicp') {
      heading.push(this.twoColumnHeader(headTextMargin,subTitles,colCount))
    } else {
      heading.push(this.threeColumnHeader(headTextMargin,subTitles,colCount))
    }
    //dummary col value for colSpan
    for (var idx=1;idx < colCount;idx++) {
        heading.push({})
    }
    return heading
}

private getTableHeader(headCols) {
  var data = []
  headCols.forEach(element => {
    data.push(
      { text: element, style: 'tableHeader', bold: true, alignment: 'center', margin: [0, 5, 0, 5], fontSize: '9'}
    )
  });
  return data
}

private getColVals(cols: any[], doc: any, idx: number) {
  var row: any[] = []; 
  row.push(idx);

  cols.forEach((e: any) => {
    let data: any = {
      lat: undefined,
      lng: undefined
    };

    if (e.location === "google") {
      e.field.forEach((res: any) => {
        if (res.suffix === "N") {
          data.lat = this.LinkGenerate(doc, res.name, e.type);
        } else if (res.suffix === "E") {
          data.lng = this.LinkGenerate(doc, res.name, e.type);
        }
      });
      const mapLink = `https://www.google.com/maps?q=${data.lat},${data.lng}`;

      row.push({
        text: this.helperService.getDataFromRow(doc, e.field, e.type),
        alignment: e.alignment,
        link:mapLink
      });

    } else {
      row.push({
        text: this.helperService.getDataFromRow(doc, e.field, e.type),
        alignment: e.alignment
      });
    }
  });

  return row;
}


// if (data.lat && data.lng) {
//   console.log("data.lat");
//   row.push({
//     text: this.helperService.getDataFromRow(doc, e.field, e.type),
//     alignment: e.alignment,
//     link: 'http://www.google.com'
//   });
// }
private async getImage(url) {
  return await this.helperService.ImagetoDataURL(url).then(dataUrl => {
    if(dataUrl) {
      return dataUrl;
    }
  });
}
LinkGenerate(row, col: any, type, prefix?: any, suffix?: any) {
  if (!row) return '';

  if (Array.isArray(col)) {
    return col
      .map((c) => this.LinkGenerate(row, c, c.type || type, prefix, suffix))
      .join('');
  }

  if (typeof col === 'object' && col !== null) {
    return this.LinkGenerate(
      row,
      col.name,
      col.type || type,
      col.prefix,
      col.suffix
    );
  }

  const dot = col.indexOf('.');
  if (dot > 0 && row[col.substr(0, dot)]) {
    return this.LinkGenerate(
      row[col.substr(0, dot)],
      col.substr(dot + 1),
      type,
      prefix,
      suffix
    );
  }

  if (row) {
    const val = this.helperService.formatData(row[col], type);
    return val !== '' ? (prefix || '') + val + (suffix || '') : '';
  }

  return '';
}







}
