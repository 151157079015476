import { HelperService } from 'src/app/services/helper.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/internal/Observable';
import { Subject, Subscription } from 'rxjs';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {
  private _userAuthData: any; // Save logged in user data
  private _userProfile: Observable<any>;
  public selectedOrgId = new Subject<string>()
  reqSubscription:Subscription[]=[]

  public user: Observable<any>;
  constructor(
        private http: HttpService,
        private router: Router,
        private helperService:HelperService      ) {
        this.helperService.userDataEmitter(JSON.parse(localStorage.getItem("lts_auth")))
        this.setSelectedOrgId(localStorage.getItem("selectedOrgId"))

  }
  ngOnInit() {
    //load existing credentials from local storage
  }

  ngOnDestroy() {
    this.reqSubscription.forEach(sub => sub.unsubscribe())
  }


/****
 *
 *  User Defined Methods
 *
 *****/

// Sign in with email/password
SignIn(body) {

  return this.http.login(body).subscribe((result: any) => {
      if (result) {
        this.helperService.userDataEmitter(result)
        localStorage.setItem('lts_auth', JSON.stringify(result));
        localStorage.setItem('selectedOrgId', result?.org?._id)
        this.setSelectedOrgId(result?.org?._id)
        localStorage.setItem('token', result?.token)
        this.router.navigate(['/']);
      }
    })
}



loadUserProfile() {
  this.reqSubscription.push(
    this.http.getDocById('org_config', this.helperService.getOrgId()).subscribe((res: any) => {
      if (res) {

      }
    })
  )
}


ForgotPassword() {

}

  callRedirect() {
      this.router.navigate(['/']);
  }

  // Sign out
  SignOut() {
    localStorage.removeItem('lts_auth');
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }


/**
*  Getter / Setter Methods
*/
public get userAuthData(): any {
  if (this._userAuthData)
      return this._userAuthData
  else
    return JSON.parse(localStorage.getItem('lts_auth'));
}

public set userAuthData(value: any) {
  this._userAuthData = value;
  localStorage.setItem('lts_auth', JSON.stringify(value));
}

public get userProfile(): any{
  if (this._userProfile)
      return this._userProfile;
  else {
    this._userProfile = JSON.parse(localStorage.getItem('user_profile'));
    return this._userProfile
  }
}
public set userProfile(value: any) {
  this._userProfile = value
  localStorage.setItem('user_profile', JSON.stringify(value));
}

public setSelectedOrgId(orgId:string) {
  this.selectedOrgId.next(orgId)
  localStorage.setItem('selectedOrgId',orgId)
}

public get role(): string {
  return this.user['role'];
}

 // Returns true when user is looged in and email is verified
get isLoggedIn(): boolean {
  const user = JSON.parse(localStorage.getItem('lts_auth'));
  return (user !== null && user.emailVerified !== false) ? true : false;
}

get token():any {
  return localStorage.getItem('token')
}


// Getter / Setter methods ends

}

