<mat-toolbar class="layout-toolbar">
  <a routerLink="{{languageService.home}}"><img [src]="logo" alt="" class="img"></a>
  <h3 class="proj_name">{{orgName}}</h3>
  <span class="filler">
  </span>
  <form *ngIf="userData.org['type']=='CO' && orgList">
    <mat-form-field style="min-width:250px !important;margin-top: 10px;" class="app-toolbar">
      <mat-select placeholder="Select Organization" (selectionChange)="setSelectedOrgId($event)" [value]="selectedOrgId">
        <mat-option *ngFor="let org of orgList" [value]="org._id">
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <span class="account_icon">
    <mat-icon>notifications</mat-icon>
  </span>

  <button mat-icon-button (click)="toggleNav()">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
