import { Injectable } from '@angular/core';

@Injectable()
export class LocalstorageService {
  intervalCount: number = 1000 * 60;
  storageList: any = [];

  getLocalStorage(key: string) {
    var local = localStorage.getItem(key);
    if (key && local) {
      return JSON.parse(local);
    }
    return null;
  }

  setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeStorage(keys: string[]) {
    keys.forEach(element => {
      localStorage.removeItem(element);
    });
  }

  clearAllStorage() {
    localStorage.clear();
  }

  timeInterval(key: any) {
    this.storageList[key] = setTimeout(() => {
      this.removeStorage(key);
      this.storageList.splice(this.storageList.indexOf(key), 1);
    }, this.intervalCount);
  }

}
