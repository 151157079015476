import { DataService } from 'src/app/services/data.service';
import { GeoLocationService } from './geolocation.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from './language.service';
import { AuthService } from './auth.service';
import { AuthGuard, LoginAuthguard } from './auth.guard.service';
import { LocalstorageService } from './localstorage/localstorage.service';
import { SnackbarService } from './snackbar/snackbar.service';
import { HelperService } from './helper.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './http.service';
import { PdfService } from './pdf.service';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    LanguageService,
    AuthGuard,
    AuthService,
    LoginAuthguard,
    SnackbarService,
    LocalstorageService,
    HelperService,
    HttpService,
    PdfService,
    DataService,
    GeoLocationService
  ]
})
export class ServicesModule { }
