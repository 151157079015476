import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { DataGridComponent } from '../data-grid/data-grid.component';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.scss']
})
export class AttendanceListComponent implements OnInit , AfterViewInit{
  pageTitle="Daywise Attendance List"
  page="attendance-list"
  data:any;
  visibleAdd: boolean = false;
  reqSubscription: Subscription[] = []

  @ViewChild('DataGridComponent', { static: true }) datagrid: DataGridComponent

  constructor(
    private dataService : DataService,
    private router: Router
  ) {
    this.reqSubscription.push(this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngAfterViewInit();
      }
    }));
      var userDetails = JSON.parse(localStorage.getItem('lts_auth'));
      // if(userDetails) {
      //   this.visibleAdd = (userDetails.org?.type == 'PO' && userDetails.role > 0) ? true : false;
      // }
   }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.reqSubscription.push(
      this.dataService.getEmployeeAttendanceList(moment().startOf('day').format(), moment().endOf('day').format()).subscribe(response=>{
        this.data = response
      })
    )
  }

  ngOnDestroy() {
    this.reqSubscription.forEach(sub => sub.unsubscribe())
  }
}
