<button class="btn-close" mat-dialog-title (click)="onNoClick()"></button>
<div mat-dialog-title style="text-align: center;">{{pageTitle}}</div>
<div mat-dialog-content>
    <form [formGroup]="frmChangePassword" fxLayout="column" fxLayoutAlign="center center" class="content">
        <div fxLayout="row" fxFlex="100%" class="content">
            <mat-form-field fxFlex="100%" *ngIf="oldPasswordVisible">
                <mat-label>Old Password</mat-label>
                <input matInput placeholder="Old Password"
                    formControlName="old_pwd" type="password">
                    <mat-error *ngIf="frmChangePassword.controls['old_pwd'].hasError('required')">
                        Old Password is Required
                    </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="100%" class="content">
            <mat-form-field fxFlex="100%">
                <mat-label>New Password</mat-label>
                <input matInput placeholder="New Password"
                    formControlName="new_pwd" type="password" required>
                    <mat-error *ngIf="frmChangePassword.controls['new_pwd'].hasError('required')">
                        New Password is Required
                    </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="100%" class="content">
            <mat-form-field fxFlex="100%">
                <mat-label>Confirm New Password</mat-label>
                <input matInput placeholder="Confirm New Password"
                    formControlName="confirm_new_pwd" type="password" required>
                <mat-error *ngIf="frmChangePassword.controls['confirm_new_pwd'].hasError('confirm_new_pwdValidator')">
                    Password doesn't match
                </mat-error>
                <mat-error *ngIf="frmChangePassword.controls['confirm_new_pwd'].hasError('required')">
                    Confirm Password is Required
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" class="content margin-bottom-10" fxLayoutAlign="end">
            <button mat-raised-button class="cancel_but" (click)="onNoClick()"
                >Cancel</button>
            <button mat-raised-button class="submit_but" (click)='save()'>Submit</button>
        </div>
    </form>
</div>