import { AuthService } from 'src/app/services/auth.service';
import { Emitter, EmitterMessage } from 'emitter-io';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject }  from 'rxjs';
import { environment } from 'src/environments/environment';
declare let emitter:Emitter;
@Injectable({
  providedIn: 'root'
})

export class GeoLocationService implements OnInit, OnDestroy {
    public client:Emitter
    private geoData = new Subject<any>();
    public isConnected = false
    // Observable string streams
    geoData$ = this.geoData.asObservable();

    constructor(
      private authService:AuthService
    ){
    }
    ngOnInit() {

    }

    public watchChannel(channelName:string)
    {
        this.client = emitter.connect({host:environment.geoLocationBaseUrl,port:environment.gepLocationPort})
        this.client.on("error",(msg:EmitterMessage)=>{
            console.log("Error", msg)
            })
        this.client.on("connect",(msg:EmitterMessage)=>{
            this.isConnected = true
            console.log("Emitter Server Connected", msg)
            this.client.subscribe({
                key:'SLSBkNXGN7W6lYYmAcmnhALd7R4GSobF', //to be call subscription api and get the key
                channel:channelName
            })
            this.client.on("message",(msg:EmitterMessage)=>{
                var data:any = msg.asObject()
                this.geoData.next({emp_id: msg.channel.split("/")[2], lat:data["lat"],lng:data["lng"],dt:data["t"]});
               // console.log(msg)
            })
        })
    }

    public channelUnSubscription(channelName:string) {
        this.client.unsubscribe({
            key:'SLSBkNXGN7W6lYYmAcmnhALd7R4GSobF', //to be call subscription api and get the key
            channel:channelName
        })
    }

    public disconnect() {
        this.client.disconnect()
    }
    ngOnDestroy() {
        this.disconnect()
    }

    genTest() {
        var count=1
        setTimeout(()=>{
            this.client.publish({
              key:'DPJny87PDCpT8h9uui8yEiIJjOdszCWO',
              channel:`location/${this.authService.selectedOrgId}/+`,
              message: '{"msg": "hello from Web-' + count++ + '"}'
          });
          console.log("Published");
          },2000);
    }
}
