<div class='employee_list_container'  *ngIf="isDataLoaded">
    <div class='title'>
      <h2 class="page-title">{{_pageTitle}}</h2>
      <h5>{{_reportDate}}</h5>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <form fxFlex="23%">
        <div fxFlex="100%" fxLayout="row">
          <mat-form-field class="search" fxFlex="100%">
            <input matInput type="text" autocomplete="off" [formControl]="searchText"
              placeholder="{{languageService.search}}" onkeypress="return event.keyCode != 13;">
            <mat-icon matPrefix>search</mat-icon>
            <mat-icon matSuffix *ngIf="search.searchText" class="searchIcon" (click)="clearSearch()">close</mat-icon>
          </mat-form-field>
        </div>
      </form>
      <div fxFlex fxLayoutAlign="end">
        <div fxflex fxLayout="column" *ngIf="config[this._page].enable"  fxLayoutAlign="end">
          <form [formGroup]="dateForm" fxFlex="100%" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
            <mat-form-field *ngIf="config[this._page]['options']['start_date']" fxFlex="{{config[this._page]['options']['end_date'] ? '25%' : '40%'}}">
              <mat-label>Start Date</mat-label>
              <input formControlName="start_date" (dateChange)="startDateChange($event)" matInput [matDatepicker]="dp1"
                disabled [max]="_calMaxDate">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1 disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="config[this._page]['options']['end_date']" fxFlex="25%">
              <mat-label>End Date</mat-label>
              <input formControlName="end_date" matInput [matDatepicker]="dp2" [min]="dateForm.value.start_date" [max]="_calMaxDate"
                disabled>
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2 disabled="false"></mat-datepicker>
              <mat-error *ngIf="dateForm.controls.end_date.hasError('matDatepickerMin')">End date should be greater than start date</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="config[this._page]['options']['type']">
              <mat-label>Leave Type</mat-label>
              <mat-select formControlName="type" placeholder="Select Leave Type">
                <mat-option *ngFor="let l of config[this._page]['typeOptions']" [value]="l.id">
                  {{ l.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="config[this._page]['options']['status']">
              <mat-label>Leave Type</mat-label>
              <mat-select formControlName="type" placeholder="Select Leave Type">
                <mat-option *ngFor="let l config[this._page]['statusOptions']" [value]="l.id">
                  {{ l.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button class="showColor submit_but" style="height:46px" 
              (click)="applyFilter()"><b>{{_selectable ? 'Generate' : 'Show'}}</b></button><br>
          </form>
        </div>

       <!-- Hide PDF and Excel icons based on page title -->
  <ng-container *ngIf="_pageTitle !== 'Add Live Location' && _pageTitle !== 'Remove Live Location'">

    <ng-container *ngIf="_pageTitle ==='Employee List'">
    <button mat-raised-button  class="showColor submit_but" style="height:46px" (click)="GetAllEmployee()" *ngIf="userDetailsService?true:false">{{flag?'Show All Employee':'Show Active Employee'}} </button>
    </ng-container>


    <button mat-icon-button>
      <i class="fa fa-file-pdf-o" (click)="generatePdf()" style="font-size: x-large;color:maroon" aria-hidden="true"></i>
    </button>
    <button mat-icon-button>
      <i class='fa fa-file-excel-o' (click)="generateCSV()" style="font-size: x-large;color:green" aria-hidden="true"></i>
    </button>
  </ng-container>      
          <button mat-raised-button class="showColor submit_but" style="height:46px" (click)="setPing(); hide=true;" [disabled]="hide" *ngIf="_pageTitle === 'Add Live Location'">Add Live Location</button>
          <button mat-raised-button class="showColor submit_but" style="height:46px" (click)="removePing(); hide1=true;" [disabled]="hide1" *ngIf="_pageTitle === 'Remove Live Location'">Remove Live Location</button>
      </div>
      <div fxFlex="3%" fxLayoutAlign="end" *ngIf="_visibleAdd">
        <button mat-mini-fab class="submit_but_plus" matTooltip="Add {{pageTitle}}"
          (click)='openAttendanceAdd()'>
          <mat-icon class="add_icon">add</mat-icon>
        </button>
      </div>
    </div>
    <app-dynamic-mat-table [data]="dataSource" (actionEvent)="actions($event)" [columns]="_columns" [moreOptions]='_moreOptions' [page]='_page'>
    </app-dynamic-mat-table>
    <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[50, 100, 200]" [showFirstLastButtons]="true"
        [length]="totalSize" [pageIndex]="currentPage">
      </mat-paginator>
</div>


<ng-template #attendanceAddDialog let-data>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle style="text-align:center">Add Attendance</h2>
  <mat-dialog-content>
      <dynamic-form [formname]="'attendance'"></dynamic-form>
  </mat-dialog-content>
</ng-template>

