import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NavItem} from '../../../../../configs/menu';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordComponent } from 'src/app/modules/page/reset-password/reset-password.component';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  user_details: any = JSON.parse(localStorage.getItem('lts_auth'));
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(
    private dialog: MatDialog,
  public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
  }
  /**LOgout api call*/
  onLogout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      if (item.displayName == "Sign-out") {
        this.onLogout()
      } else if(item.iconName == 'lock_open') {
        var dialogRef = this.dialog.open(ResetPasswordComponent, {
          width: '40%',
          height: 'auto',
          data: {
            frmLeftMenu: true,
            old_pwd: true,
            _id: this.user_details?._id
          }
        })
      } else {
        this.router.navigate([item.route]);
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
