import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-without-topmenu',
  templateUrl: './without-topmenu.component.html',
  styleUrls: ['./without-topmenu.component.scss']
})
export class WithoutTopmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
