<div class="add_container" fxLayout="column">
    <div *ngIf="showTitle" class='title'>
        <h2 class="page-title" *ngIf="formName != 'attendance'">{{pageTitle}}</h2>
    </div>
    <div fxLayout="column" fxFlex="100%" class="form-content">
        <div fxLayout="row" fxFlex="100%">
            <formio fxFlex="100%" [form]="form" [submission]='submission' (submit)="getData($event.data)"></formio>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" class="content-but">
            <button mat-raised-button *ngIf="showCancel" class="cancel_but" (click)="cancel()">Cancel</button>
            <button *ngIf="!hideSendButton" mat-raised-button class="submit_but" (click)="onSubmit(formName)" >{{isEdit ? 'Save' : eid ? 'Send' : 'Add'}}</button>
            <!-- <button mat-raised-button *ngIf="hi='true'" class="submit_but" (click)="onSubmit(formName)">{{isEdit ? 'Save' : eid ? 'Send' : 'Add'}}</button> -->
        
            <button *ngIf="hideSendButton" mat-raised-button class="submit_but" (click)="onSubmit(formName);"> Submit </button>

        </div>
    </div>
</div>
