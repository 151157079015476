import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-dynamic-mat-table',
  templateUrl: './dynamic-mat-table.component.html',
  styleUrls: ['./dynamic-mat-table.component.scss']
})
export class DynamicMatTableComponent implements OnInit, AfterViewInit {

  @Input('data') dynamicData: MatTableDataSource<any> = new MatTableDataSource<any>();
  @Input('columns') dynamicColumns: any;
  @Input('sort') disableSorting: any;
  @Input('page') page: any;
  @Input('moreOptions') moreOptions: boolean = true;
  @Output() actionEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  public displayColumns: any
  public dataSource: any
  reqSubscription: Subscription[] = [];
  user_Data: any = JSON.parse(localStorage.getItem('lts_auth'));
  role: any;

  defaultFields = ['text', 'code', 'mobile', 'date', 'time', 'status', "dateTime", 'duration'];
  dashboardLinkFields = ['_id', 'eid', 'emp.name', 'emp_id', 'name','sign_in.t','sign_out.t','signin','signout'];
  employeeNavigatePages = ['employee', 'sign-in', 'late', 'leave', 'attendance', 'attendance-list', 'leave-request', 'leave-applied', 'notification']

  constructor(
    public helperService: HelperService) {
      this.role = this.user_Data?.role;
     }

  ngOnInit(): void {
    this.columnData(this.dynamicColumns);
    //Dynamic column header for slider
    this.helperService.tableSubject.subscribe(res => {
      this.dynamicColumns = res.column;
      this.dynamicData = res.data;
      this.columnData(res.column);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dynamicColumns && changes.dynamicColumns.currentValue) {
      this.columnData(changes.dynamicColumns.currentValue);
    }
  }

  ngAfterViewInit() {
    this.dynamicData.sort = this.sort;
  }

  columnData(column) {
    this.displayColumns = column?.map((c: any) => c.columnDef);
    this.reqSubscription.push(
      this.dynamicData.connect().subscribe((data: any) => {
        this.dataSource = data
      })
    )
  }

  enableDefaultCell(type, row) {
    const arrayList = row ? this.dashboardLinkFields : this.defaultFields;
    const value  = arrayList.includes(type);
    return value;
  }

  employeeDashboard(row) {
    const thisPage = this.employeeNavigatePages.includes(this.page);
    var id;
    if(thisPage) {
      id = row.eid ? row.eid : row.emp_id ? row.emp_id : row._id;
    }
    if(id) {

      // let Data = {
      //   emp_id:row.emp_id,
      //   date:row.date     
      
      // }
      sessionStorage.setItem('geo_data', JSON.stringify(row));
      // console.warn(row);
      this.helperService.navigateToEmployeeDashboard(id);
    }
  }

  /**unsubscribe subscriptions on component destroy */
  ngOnDestroy() {
    this.reqSubscription.forEach(sub => sub.unsubscribe())
  }

  /**more option button click event emitted to parent component */
  doAction(methodName: any, data: any) {
    this.actionEvent.emit({ methodName: methodName, value: data })
  }

  showValue(row,col) {
    return this.helperService.getDataFromRow(row, col.field, col.type)
    // if (col.edit) {
    //   return `<span (click)="doAction('edit',row)">${result}</span>`
    // }
    // return result
  }

  visibleCancel(row) {
    if (row.status == 'A' && (new Date(row.start_date) > new Date())) {
      return true;
    } else {
      return false;
    }
  }

  public isSortingDisabled(columnText: string): boolean {
    if (this.disableSorting) {
      return this.disableSorting;
    } else {
      return false; // add your logic here
    }
  }
}
