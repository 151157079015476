import { HelperService } from 'src/app/services/helper.service';
import { DialogService } from './../../../../services/dialog.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { TemplateRef } from '@angular/core';

@Component({
  selector: 'app-employee-panel',
  templateUrl: './employee-panel.component.html',
  styleUrls: ['./employee-panel.component.scss'],
})
export class EmployeePanelComponent implements OnInit {
  visible = false;
  employeeDetail: any;
  employeeSince: string | Date;
  reqSubscription = [];
  employeeDate: string | Date;
  employeeAge: number;
  idCardType: any;
  editForm: string;
  visibleEdit: boolean = true;
  @Output() openSlider = new EventEmitter();
  @ViewChild('popupDialog', { static: true }) popupDialog: TemplateRef<any>;

  constructor(
    public languageService: LanguageService,
    private router: Router,
    private dialogService: DialogService,
    public helperService: HelperService
  ) {
    this.reqSubscription.push(
      this.helperService.employeeSubject.subscribe((res) => {
        this.visible = true;
        this.employeeDetail = res;
        this.employeeSince = this.employeeDetail.doj
          ? new Date(this.employeeDetail.doj)
          : '';
        this.employeeDate = this.employeeDetail.dob
          ? new Date(this.employeeDetail.dob)
          : '';
        if (this.employeeDate) {
          this.employeeAge = this.helperService.calculateAge(this.employeeDate);
        }
      })
    );
  }

  dataobjects: any;
  userDetailsService:boolean
  ngOnInit() {
    this.checkEmployeePermission();
    this.dataobjects = JSON.parse(localStorage.getItem('lts_auth'));
    if (this.dataobjects.role >1 ) {
     this.userDetailsService =true
    }else{
      this.userDetailsService=false
    }
  }

  checkEmployeePermission() {
    var userDetails = JSON.parse(localStorage.getItem('lts_auth'));
    if (userDetails) {
      this.visibleEdit =
        userDetails.org?.type == 'PO' && userDetails.role > 1 ? true : false;
    }
  }

  openNotificationDialog(emp: any) {
    this.editForm = 'notification';
    this.dialogService.openDialog(this.popupDialog, 800, emp);
  }

  viewAttendance() {
    this.openSlider.next('Attendance');
  }

  addlivePing() {
    this.openSlider.next('AddPing');
  }
  removePing() {
    this.openSlider.next('removePing');
  }

  uploadFile($event) {
    console.log($event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (rs) => {
        const imgBase64Path = e.target.result;
        var profilePhoto = {
          emp_id: this.employeeDetail._id,
          data: imgBase64Path,
        };
        this.helperService.updateProfilePicture(profilePhoto, 'update');
      };
    };
    reader.readAsDataURL($event.target.files[0]);
  }

  viewLeave() {
    this.openSlider.next('Leave');
  }

  editProfile(emp) {
    this.router.navigate([
      'page/employee/edit/' + this.employeeDetail._id + '/profile',
    ]);
  }

  goBack() {
    this.router.navigate(['page/employee']);
  }

  ngOnDestroy() {
    this.reqSubscription.forEach((sub) => sub.unsubscribe());
  }
}
