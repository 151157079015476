<div class="flexbox-parent">
  <div class="layout-container">
    <mat-sidenav-container class="layout-sidenav-container" hasBackdrop="false"
      [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav class="left-sidenav matAnim" #snav position="end" opened="true" [mode]="'over'"
        [ngClass]="isOpened ? isExpanded ? 'menu':'mobile' : 'not_menu'" [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="56" class="side_nav" disableClose>
        <div [@slideInOut]>
          <div class="close" (click)="toggleNavIcon()">
            <mat-icon class="keyboard_icon" *ngIf="isExpanded">keyboard_arrow_right</mat-icon>
            <mat-icon class="keyboard_icon" *ngIf="!isExpanded">keyboard_arrow_left</mat-icon>
          </div>
          <div *ngIf="isExpanded" fxflex fxLayout="column" fxLayoutAlign="center center" class="profile">
            <img [src]="image" class="profileSize">
            <p class="userText">{{userName}}</p>
          </div>
          <mat-nav-list>
            <app-menu-list-item *ngFor="let item of navMenu" [item]="item"></app-menu-list-item>
          </mat-nav-list>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<ng-template #template>
  <router-outlet>
  </router-outlet>
</ng-template>
