


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../snackbar/snackbar.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  date = new Date().getTime();
    constructor(public authService: AuthService,
      public router:Router,private snackbar:SnackbarService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request);
    }
}
