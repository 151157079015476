import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


export function confirm_new_pwdValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirm_new_pwdValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirm_new_pwdValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  frmChangePassword: FormGroup;
  pageTitle = "";
  oldPasswordVisible: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private httpService: HttpService,
    private snackBarService: SnackbarService) {
      this.createControls();
      this.changeValidators(this.dialogData.old_pwd);
      this.pageTitle = this.dialogData.frmLeftMenu ? 'Change Password' : 'Reset Password';
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  changeValidators(option) {
    if(option) {
      this.oldPasswordVisible = true;
      this.frmChangePassword.controls["old_pwd"].setValidators(Validators.required);
    } else {
      this.oldPasswordVisible = false;
      this.frmChangePassword.controls["old_pwd"].clearValidators();
    }
    this.frmChangePassword.get("old_pwd").updateValueAndValidity();
  }

  /**
* Create From Controls with default value
*/
  createControls() {

    this.frmChangePassword = this.formBuilder.group({
      id: new FormControl(this.dialogData._id),
      old_pwd: new FormControl(''),
      new_pwd: new FormControl('', Validators.compose([
        Validators.required])),
      confirm_new_pwd: new FormControl('', Validators.compose([
        Validators.required])),
    }, { validator: confirm_new_pwdValidator('new_pwd', 'confirm_new_pwd') });
  }

  /**Save Api Call */
  save() {
    this.helper.validateAllFormFields(this.frmChangePassword);
    if (this.frmChangePassword.valid) {
      var body = this.frmChangePassword.value;
      if(!this.oldPasswordVisible) {
        delete body['old_pwd'];
      }
      console.log('body', body)
      this.httpService.resetPassword(body).subscribe((res:any) => {
        if(res.error_msg) {
          this.snackBarService.snackbarError(res.error_msg, 'center');
          return;
        }
        if(res) {
         if (this.dialogData.frmLeftMenu) {
          this.authService.SignOut();
        }
        this.dialogRef.close(true);
        this.snackBarService.snackbarSuccess('Password has been changed successfully');
        }
      })
    }
    else {
      return;
    }
  }

}
