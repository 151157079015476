import { Injectable } from '@angular/core';

@Injectable()
export class LanguageService {
  constructor() { }

  //Common labels

  add = 'Add';
  save = 'Save';
  cancel = 'Cancel';
  submit = 'Submit';

  /*form*/
  firstName = 'First Name';
  middleName = 'Middle Name';
  aliasName = 'Alias Name/Display Name';
  zip = 'Zip';
  lastName = 'Last Name';
  dob = 'Date of Birth';
  doj = 'Date of Joining';
  employeeSince = 'Employee Since';
  mobile = 'Mobile #';
  email = 'Email ID';
  address1 = 'Door #/Building Name';
  address2 = 'Street';
  area = "Area";
  city = 'City/Town';
  zipcode = 'Zipcode';
  idNumber = 'ID Number';
  ssn = 'SSN';
  country_code = "Country";
  state_code = "State";
  gender = "Gender";
  idcard_type = "ID Card Type";
  reason = "Reason";
  enterYourEmail = "Enter Your Email";
  yes = "Yes";
  no = "No";


  /**Pattern */
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;


  /**Lable/Placeholders */
  // <-----Login----->
  name = 'Name';
  desc = 'Description';
  token = 'token';
  login = 'login';
  search = 'Filter List';
  signOut = 'Signout';

  // <-----EmployeeTable---->
  addEmploye = "Add Employee";
  employee_from = "Employee From";
  settingsMenu = 'Settings';


  // <-----Layout---->
  employeeMenu = "Employee";
  userMenu = "User";
  notificationMenu = "Notification";
  changePasswordMenu = "Change Password";
  signin = 'Signin'

  //<----ChangePassword-->
  changePwdTitle = 'Change Password';
  currentPwd = 'Current Password';
  newPwd = 'New Password';
  confirmPwd = 'Confirm Password';

  //<---ForgotPassword--->
  forgotPwdTitle = 'Forgot Password?';

  //<----Login--->
  username = 'Username';
  password = 'Password';
  loginButton = 'Login';

  //<---Layout-Navigation-->

  /**Admin DashBoard */
  searchEmployee = "Search Employee";


  /**Router Navigation Path */
  home = '/';
  
  /**Page Title(s) */
  organizationListTitle = 'Organization List';
  listTitle = "Employee List";
  attendanceList = 'Attendance List';
  projectList = 'Project List';

}