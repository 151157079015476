import { DataService } from 'src/app/services/data.service';
import { ReportService } from '../../../services/report.service';
import { AuthService } from '../../../services/auth.service';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridComponent implements OnInit, OnChanges, OnDestroy {
  public dataSource = new MatTableDataSource<any>();
  public pageSize = environment.pageSize;
  public currentPage = 0;
  public totalSize = 0;
  public searchText: FormControl = new FormControl('', []);
  public search = { searchText: '' };
  currentGridFilter: any;
  searchAdvanceSub!: Subscription;
  reqSubscription: Subscription[] = [];
  isDataLoaded = false;
  /**filter variable declaration */
  filterVisible: boolean = false;
  employeeList: any;
  /**Get current month's first and current date as default start and end date */
  firstDay: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  lastDay: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  /**default date */
  dateForm: FormGroup;
  projectForm: FormGroup;
  maxDate: any;
  config: any;
  hide = false;
  hide1 = false;

  //Input paramets
  _pageTitle: any;
  _columns: any;
  _data: any;
  _page: any;
  _pageData: any;
  _pdfHeading: any;
  _rptTemplate: any;
  _groupBy: any;
  _empId: any;
  _empIds: any;
  _startDate = moment().startOf('day');
  _endDate = moment().endOf('day');
  _calMaxDate = new Date();
  _selectable: boolean;
  _selectedEmployeesId = [];
  _visibleAdd: boolean = false;
  selectedRow: any;
  projectList: any[] = [];
  attendanceList: any[] = [];
  _moreOptions: boolean = false;
  //  model: any = null;
  startDates: any;
  endDates: any;
  _reportDate = this.helperService.showFullDate(moment());
  selectedOrgId: string;
  gridColumns: any;

  @Input()
  set page(val: any) {
    this._page = val;
  }

  @Input()
  set pageTitle(val: any) {
    this._pageTitle = val;
  }

  @Input()
  set pageData(val: any) {
    this._pageData = val;
  }

  @Input()
  set empIds(val: any) {
    this._empIds = val;
  }

  @Input()
  set empId(val: any) {
    this._empId = val;
  }

  @Input()
  set rptTemplate(val: any) {
    this._rptTemplate = val;
  }

  @Input()
  set pdfHeading(val: any) {
    this._pdfHeading = val;
  }
  @Input()
  set groupBy(val: any) {
    this._groupBy = val;
  }

  @Input()
  set isSelectable(val: any) {
    this._selectable = val;
  }

  @Input()
  set startDate(val: any) {
    this._startDate = val;
  }

  @Input()
  set endDate(val: any) {
    this._endDate = val;
  }

  @Input()
  set visibleAdd(val: any) {
    this._visibleAdd = val;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('attendanceAddDialog', { static: true })
  attendanceAddDialog: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    public languageService: LanguageService,
    public helperService: HelperService,
    private dataService: DataService,
    private router: Router,
    private reportService: ReportService,
    private http: HttpService,
    private snackbar: SnackbarService,
    private dialogService: DialogService
  ) {
    this.authService.selectedOrgId.subscribe((id) => {
      this.selectedOrgId = id;
    });
    this.reqSubscription.push(
      this.dataService.getEmployeeList().subscribe((res) => {
        this.employeeList = res;
      })
    );
    this.reqSubscription.push(
      this.dataService.getProjectList().subscribe((res) => {
        this.projectList = res;
      })
    );
    this.frmDateForm();
  }
  
  dataobjects: any;
  userDetailsService:boolean

  ngOnInit(): void {
    //set text filter
    this.searchAdvanceSub = this.searchText.valueChanges
      .pipe(debounceTime(500))
      .subscribe((val: any) => {
        this.search.searchText = val.trim().toLowerCase();
        this.filter();
      });
      this.dataobjects = JSON.parse(localStorage.getItem('lts_auth'));
      if (this.dataobjects.role >1 ) {
       this.userDetailsService =true
      }else{
        this.userDetailsService=false
      }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this._pageTitle && this._page && this._pageData) {
      //Reset start & end date to current date
      if (!this._startDate) this._startDate = moment().startOf('day');
      this._endDate = moment().endOf('day');
      this.dateForm.patchValue({
        start_date: this._startDate.format(),
        end_date: this._endDate.format(),
      });
      //load the data from the existing subscription result
      // if(this._pageTitle ='Device Ping'){
      this.loadGridData(this._pageData);

      // }
    }
  }
  loadGridData(data) {
    debugger;
    var config = this.helperService.Config;
    this.config = config?.default;
    //Load data to grid
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.totalSize = this.dataSource.data.length;
    this._columns = this.config[this._page].gridColumns;
    this.isDataLoaded = true;
    this.refreshReportDate();
  }

  generatePdf() {
    debugger;
    this.reportService.generatePdf(
      this._rptTemplate ? this._rptTemplate : this._page,
      this.dataSource.data,
      this._groupBy,
      this._pdfHeading || []
    );
  }

  flag: boolean = true;
  showbuttons:boolean = false;
  // this.dataobjects.role > 1
  GetAllEmployee() {
    // console.log("LOADED");
    if (this.flag) {
      this.http.getAllEmployees().subscribe(
        (data) => {
          // Handle the data here, such as assigning it to a variable for display
          this.dataSource.data = data;
          // this.dataService.data(data);
          // console.log(data);
          
        },
        (error) => {
          console.error('Error fetching employees:', error);
        }
      );
      this.flag = false;
    } else {
      var filter = { status: 'A' };
      this.http
        .getDocListBySearch('employee', filter)
        .subscribe((docs: any) => {
             if (docs.data) {
            this.dataSource.data = docs.data;
            // result.next(docs.data);
            this.dataService.data(docs.data);
          }
        });
      this.flag = true;
    }
  }

  generateCSV() {
    this.reportService.generateCSV(
      this._rptTemplate ? this._rptTemplate : this._page,
      this.dataSource.data
    );
  }

  getEmployeeDetails(event) {
    var id = event.value;
    if (id == '') {
      this.dataSource.data = this.employeeList;
    } else {
      this.dataSource.data = this.employeeList.filter((o) => o.pid == id);
    }
    this.loadGridData(this.dataSource.data);
    this.helperService.getUpdatedSelectedList(true);
  }

  // Search Input field.
  filter() {
    this.dataSource.filter = this.search.searchText;
  }

  /**Clear search text*/
  clearSearch() {
    this.searchText.setValue('');
    this.search.searchText = '';
  }

  /**search filter */
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  /**more options actions*/
  actions(event: any) {
    // if(this._page == "attendance-list" && event.methodName == 'view') {
    //   this.model = event.value;
    //   this.dialogService.openDialog(this.attendanceAddDialog,800, event.value)
    // }

    if (event.methodName == 'edit') {
      this.router.navigateByUrl(`/page/${this._page}/edit/` + event.value._id);
    }
  }

  frmDateForm() {
    this.dateForm = new FormGroup({
      start_date: new FormControl(this._startDate.format()),
      end_date: new FormControl(this._endDate.format()),
      type: new FormControl(''),
      status: new FormControl(''),
      pid: new FormControl(''),
    });
    this.projectForm = new FormGroup({
      pid: new FormControl(''),
    });
  }

  applyFilter() {
    const formValue = this.dateForm.getRawValue();
    this._startDate = moment(formValue.start_date).startOf('day');
    this._endDate = moment(formValue.end_date).endOf('day');
    const filterParam = {
      emp_id: '',
      type: formValue.type,
      status: formValue.status,
      start_date: this._startDate.format(),
      end_date: this._endDate.format(),
    };
    this.refreshReportDate();
    this.getDocByFilter(filterParam);
  }

  setPing() {
    const formValue = this.dateForm.getRawValue();
    this.startDates = moment(formValue.start_date).format(
      'YYYY-MM-DDT00:00:00.000Z'
    );
    this.endDates = moment(formValue.end_date).format(
      'YYYY-MM-DDT23:59:59.999Z'
    );
    const empIds = this._pageData[0].emp_id;
    const org_ids = localStorage.getItem('selectedOrgId');
    var orgId = org_ids.replace(/[\[\]"]/g, '');
    this.http
      .empAttendance(orgId, empIds, this.startDates, this.endDates)
      .subscribe((res) => {
        console.log('SUBSCRIBE', res);
      });
    this.snackbar.snackbarSuccess(
      `${this.helperService.capitalize('Location')}  has been add successfully `
    );
  }
  removePing() {
    const formValue = this.dateForm.getRawValue();
    this.startDates = moment(formValue.start_date).format(
      'YYYY-MM-DDT00:00:00.000Z'
    );
    this.endDates = moment(formValue.end_date).format(
      'YYYY-MM-DDT23:59:59.999Z'
    );
    const empIds = this._pageData[0].emp_id;
    const org_ids = localStorage.getItem('selectedOrgId');
    var orgId = org_ids.replace(/[\[\]"]/g, '');
    this.http .removemarker(orgId, empIds, this.startDates, this.endDates)
      .subscribe((res) => {});
    this.snackbar.snackbarSuccess(
      `${this.helperService.capitalize('Location')}  has been add successfully `
    );
  }

  /**Fetch grid data based on the filter applied */
  getDocByFilter(data) {
    /**
     *
     *
     * TODO
     * this is temp. fix....because still multi date PDF not ready
     *
     */
    //data['end_date'] = moment(data['start_date']).endOf('day').format()

    if (this._page == 'sign-in') {
      this.dataService
        .getSignInList(data['start_date'], data['end_date'])
        .subscribe((response) => {
          this.loadGridData(response);
        });
    } else if (this._page == 'late') {
      this.dataService
        .getLateSignInList(data['start_date'], data['end_date'])
        .subscribe((response) => {
          this.loadGridData(response);
        });
    } else if (this._page == 'leave') {
      this.dataService
        .getLeaveList(data['start_date'], data['end_date'], this._empId)
        .subscribe((response) => {
          this.loadGridData(response);
        });
    } else if (this._page == 'attendance' || this._page == 'attendance-list') {
      this.dataService
        .getEmployeeAttendanceList(
          data['start_date'],
          data['end_date'],
          this._empIds || (this._empId ? [this._empId] : [])
        )
        .subscribe((response) => {
          this.loadGridData(response);
        });
    }
    //set subtitle (i.e selected start date)]
    this._pdfHeading = [];
  }

  refreshReportDate() {
    this._reportDate = this.helperService.showFullDate(this._startDate);
    if (this._endDate.diff(this._startDate, 'd') > 0) {
      this._reportDate +=
        ' - ' + this.helperService.showFullDate(this._endDate);
    }
  }

  ngOnDestroy() {
    this.reqSubscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  startDateChange(event) {
    this.firstDay = new Date(event.value);
    this.maxDate = new Date(
      this.firstDay.getFullYear(),
      this.firstDay.getMonth() + 1,
      0
    );
  }

  openAttendanceAdd() {
    this.dialogService.openDialog(this.attendanceAddDialog, 800);
  }
}
