import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServicesModule } from './services/services.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";

/**Modules/Components import*/
import { SharedModule } from './modules/shared/shared.module';
import { MaterialModule } from './modules/shared/material.module';

/**libraries import*/
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { CdkTableModule } from "@angular/cdk/table";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormioModule } from '@formio/angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


// Component import

import { AppLayoutModule } from './modules/shared/components/layout/app-layout.module';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { TokenInterceptor } from './services/interceptor/authentication.interceptor';
import { DatePipe } from '@angular/common';
import {MAT_DATE_FORMATS} from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ServicesModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SharedModule,
    MaterialModule,
    CdkTableModule,
    DragDropModule,
    RouterModule,
    ReactiveValidationModule,
    NgxChartsModule,
    LayoutModule,
    AppLayoutModule,
    FormioModule,
    LeafletModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  DatePipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
