import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    animal: string;
    name: string;
    dialogRef: any;
    constructor(private dialog: MatDialog,
        private _snackBar: MatSnackBar,
        
        ) { }

    public openDialog(content, width?, val?): void {
        if (width==null) width = '80%'
        this.dialogRef = this.dialog.open(content, {
            width: width || '400px',
            data: val || null,
        });


        this.dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });

    }

    public closeModal() {
        this.dialogRef.close()
    }



    public openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }
}
