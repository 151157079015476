// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  orgName: '',
  production: true,
  snackBarDuration: 5000,
  logoUrl: '/assets/logo.png',
  apiBaseUrl:'https://ckicp.in:8443/api/',//Application Base Url  
  trackingBaseUrl: 'https://ckicp.in:7443/api/',
  geoLocationBaseUrl: 'https://ckicp.in',
  staticFileBaseUrl: 'https://ckicp.in',
  gepLocationPort: 9443,
  pageSize: 50, //Table default page size
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
