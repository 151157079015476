<mat-table #table [dataSource]="dataSource" cdkDropList matSort>
  <ng-container *ngFor="let column of dynamicColumns" [cdkColumnDef]="column.columnDef">
    <mat-header-cell mat-sort-header *cdkHeaderCellDef
      [ngClass]="column.class"
      [disabled]="isSortingDisabled(column)">{{column.header}}
    </mat-header-cell>
    <ng-container *ngIf="enableDefaultCell(column.type, false)">
      <mat-cell *cdkCellDef="let row" (click)="enableDefaultCell(column.field, true) ? employeeDashboard(row) : doAction('view',row)" [ngClass]="column.class ? column.class : ''">
        {{showValue(row,column)}}
      </mat-cell>
    </ng-container>
    <ng-container *ngIf="column.type == 'role'">
      <mat-cell *cdkCellDef="let row" (click)="doAction('view',row)" [ngClass]="column.class ? column.class : ''">
        {{ row[column.field] ? row[column.field] == -1 ? 'User' : row[column.field] == 1 ? 'Admin' : 'Super Admin' : ""}}
      </mat-cell>
    </ng-container>
    <ng-container *ngIf="column.type == 'image'">
      <mat-cell *cdkCellDef="let row" [ngClass]="column.class ? column.class : ''"
        (click)="helperService.navigateToEmployeeDashboard(row._id)">
        <img [src]="helperService.getProfileImage(row[column.field])" class="tableImage">
      </mat-cell>
    </ng-container>
    <ng-container *ngIf="column.type == 'edit'">
      <mat-cell *cdkCellDef="let row" (click)="doAction('edit',row)" [ngClass]="column.class ? column.class : ''">
        <mat-icon style="cursor: pointer;">
          edit</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container *ngIf="column.type == 'more'">
      <mat-cell *cdkCellDef="let row" [ngClass]="column.class ? column.class : ''">
        <button mat-button [matMenuTriggerFor]="menu" *ngIf="(row.status == 'P' || visibleCancel(row)) && moreOptions" >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button id="approve" mat-menu-item (click)="doAction('approve', row)" *ngIf="row.status == 'P'">Approve</button>
          <button id="reject" mat-menu-item (click)="doAction('reject', row)" *ngIf="row.status == 'P'">Reject</button>
          <button id="cancel" mat-menu-item (click)="doAction('cancel', row)" *ngIf="visibleCancel(row)" >Cancel</button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <ng-container *ngIf="column.type == 'user_more'">
      <mat-cell *cdkCellDef="let row" [ngClass]="column.class ? column.class : ''">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button id="edit" mat-menu-item (click)="doAction('edit', row)">Edit</button>
          <button id="reject" mat-menu-item (click)="doAction('reset_password', row)" *ngIf="role > 0">Reset Password</button>
        </mat-menu>
      </mat-cell>
    </ng-container>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
  {{displayColumns}}
  <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
</mat-table>
<div class="norecord" *ngIf="dataSource?.length===0">No records found</div>
