<mat-sidenav-container class="example-container" [hasBackdrop]="false">
  <mat-sidenav #sidenav mode="over" class="matWidth">
    <button class="btn-close" mat-dialog-title (click)="sidenav.toggle()">
    </button>
    <br>
    <div mat-dialog-content>
      <data-grid [pageTitle]="this.pageTitle" [page]="this.page" [empId]="this.employeeId" [pdfHeading]="heading" [pageData]="this.pageData" [groupBy]="'emp_id'" [startDate]="this.firstDay"></data-grid>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlex="100%">
    <div fxflex="100%" fxLayout="row" class="row-padding">
      <div fxFlex="20%" class="textCenter mtm-10">
        <div class="employeeProfile">
          <app-employee-panel (openSlider)="openSliderPopup($event)"></app-employee-panel>
        </div>
      </div>
      <div fxFlex="80%" class="mtm-10" fxLayout="column">
        <div fxflex>
          <employee-tracker [emp]="employeeDetails" [orgId]="selectedOrgId" ></employee-tracker>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
