import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from './http.service';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { AnyKindOfDictionary } from 'lodash';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  _orgList: any;
  dataobjects: any;

  private _projectList: any;
  public _employeeList: any;

  dashBoardResult = new Subject<any>();
  _today = moment().startOf('day');

  totValue = [];

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.initLoad();
  }
  async initLoad() {
    this.authService.selectedOrgId.subscribe(async (id) => {
      if (id && id != '') {
        this._employeeList = undefined;
        //load the selected customer's theme
        this.helperService.loadTheme();
        //load selected customers's dashboard data
        if (this.router.url == '/') this.loadDashboardData(this._today);
      } else this.resetData();
    });
  }

  data(res: any) {
    this.dashBoardResult.next({ model: 'employee', value: res });
  }

  loadDashboardData(startDate?) {
    if (!startDate) startDate = moment().endOf('day').format();
    //first get the employee detail, then load other data
    this.getEmployeeList().subscribe((res) => {
      //publish total emp count
      this.dashBoardResult.next({ model: 'employee', value: res });
      this.getSignInList(startDate).subscribe((res) => {
        //publish sign-in emp count
        this.dashBoardResult.next({ model: 'sign-in', value: res });
      });
      this.getLateSignInList(startDate).subscribe((res) => {
        this.dashBoardResult.next({ model: 'late', value: res });
      }); 
      
      this.getLeaveList(startDate).subscribe((res) => {
        //publish Leave Request 
        this.dashBoardResult.next({ model: 'leave', value: res });
      });
      this.getAppliedLeaveList(startDate).subscribe((res) => {
        //publish Leave Request
        this.dashBoardResult.next({ model: 'leave-applied', value: res });
      });
    });
  }

  getEmployeeList(status?): Observable<any> {
    this.dataobjects = JSON.parse(localStorage.getItem('lts_auth'));
    var result = new Subject<any>();
    if (this._employeeList) {
      setTimeout(() => {
        result.next(this._employeeList);
      }, 0);
    }
    else {
      var filter = { status: status || 'A' };
      this.httpService
        .getDocListBySearch('employee', filter)
        .subscribe((docs: any) => {
          if (docs.data) {
            this._employeeList = docs.data;
            result.next(docs.data);
          } else {
            result.next([]);
          }
        });
    }
    return result.asObservable();
  }

  //TODO
  getSignInList(startDate, endDate?): Observable<any> {
    if (!endDate) endDate = moment(startDate).endOf('day').format();
    var result = new Subject<any>();
    var filter = {
      date_column: 'dt',
      start_date: startDate,
      end_date: endDate,
    };
    //var filter = {date_column: "dt",  start_date: startDate}
    this.httpService
      .getDocListBySearch('emp_attendance', filter)
      .subscribe((docs: any) => {
        if (docs.data.length) {
          this.updateEmpInfo(docs.data); //update employee info
          result.next(docs.data);
        } else {
          result.next([]);
        }
      });
    return result.asObservable();
  }

  getLateSignInList(startDate, endDate?): Observable<any> {
    if (!endDate) endDate = moment(startDate).endOf('day').format();
    var result = new Subject<any>();
    var cutOffTime = moment(startDate).startOf('day').add(10, 'h').add(30, 'm');
    this.getSignInList(startDate, endDate).subscribe((res) => {
      var late = res.filter((e) => moment(e['in'][0]['t']).isAfter(cutOffTime));
      result.next(late);
    });
    return result.asObservable();
  }

  getLeaveList(startDate, endDate?, empId?): Observable<any> {     
    if (!endDate) endDate = moment(startDate).endOf('day').format();
    var result = new Subject<any>();
    var filter: any;  
    if (!empId) { 
      if (typeof(startDate) == "object"){
        filter = { status: 'A', start_date: startDate.format()}; 
      }else{
      filter = { status: 'A', start_date: startDate}; 
     } 
    } else {
      filter = { start_date: startDate, end_date: endDate, emp_id: empId };
    }     
    this.httpService
      .getDocListBySearch('leave', filter)
      .subscribe((docs: any) => {
        if (docs.data.length) {
          this.updateEmpInfo(docs.data);
          result.next(docs.data);
        } else {
          result.next([]);
        }
      });
    return result.asObservable();
  }

  getAppliedLeaveList(start_date?, end_date?): Observable<any> {
    var result = new Subject<any>();
    var filter = { status: 'P' };   
    if (start_date) filter['start_date'] = start_date.format();
    if (end_date) filter['end_date'] = end_date.format();
    
    this.httpService
      .getDocListBySearch('leave', filter)
      .subscribe((docs: any) => {
        if (docs.data.length) {
          this.updateEmpInfo(docs.data);
          result.next(docs.data);
        } else {
          result.next([]);
        }
      });
    return result.asObservable();
  }

  getProjectList(): Observable<any> {
    var result = new Subject<any>();
    if (this._projectList) {
      setTimeout(() => {
        result.next(this._projectList);
      }, 0);
    } else {
      this.httpService.getDocList('project').subscribe((res: any) => {
        this._projectList = res;
        result.next(this._projectList);
      });
    }
    return result.asObservable();
  }
  resetData() {
    this.dashBoardResult.next([]);
  }

  /***
   *
   *
   */
  getEmployeeAttendanceList(startDate, endDate?, empIds?): Observable<any> {
    if (!endDate) endDate = moment(startDate).endOf('day').format();
    var result = new Subject<any>();
    var filter = { start_date: startDate, end_date: endDate };
    if (empIds && empIds.length > 0) filter['emp_ids'] = empIds;

    this.httpService.attendanceList(filter).subscribe((docs: any) => {
      if (docs.data.length) {
        this.updateEmpInfo(docs.data, 'emp_id');
        result.next(_.sortBy(docs.data, 'date'));
      } else {
        result.next([]);
      }
    });
    return result.asObservable();
  }

  ngOnDestroy() {}

  async updateEmpInfo(obj: any, empCol?) {
    if (!empCol) empCol = 'eid';
    obj.map((e) => {
      e['emp'] = this._employeeList.find((o) => o._id == e[empCol]);
    });
  }

  //Org Data
  async orgList() {
    if (this._orgList) return this._orgList;
    else {
      this._orgList = await this.getOrgList();
      return this._orgList;
    }
  }

  async getOrgList() {
    var result = this.httpService.getDocList('organization');
    return result;
  }
}
