import { EmployeeDashboardComponent } from './modules/home/employee-dashboard/employee-dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamTrackerComponent } from './modules/page/team-tracker/team-tracker.component';
import { DefaultLayoutComponent } from './modules/shared/components/layout/default-layout/default-layout.component';
import { WithoutTopmenuComponent } from './modules/shared/components/layout/without-topmenu/without-topmenu.component';
import { AuthGuard } from './services/auth.guard.service';
import { EmployeeMonthlyReportComponent } from './modules/page/employee-monthly-report/employee-monthly-report';
import { AttendanceListComponent } from './modules/page/attendance-list/attendance-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: WithoutTopmenuComponent,
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
        // canActivate: [LoginAuthguard]
      },
      {
        path: '',
        component: DefaultLayoutComponent,
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'paramsChange'
      },
      {
        path: 'page',
        component: DefaultLayoutComponent,
        loadChildren: () => import('./modules/page/page.module').then(m => m.PageModule)
      },
      {
        path: 'live',
        component: DefaultLayoutComponent,
        children: [
          {
          path:'team-tracker',
          component: TeamTrackerComponent,
          }
        ]
      },
      {
        path: 'list',
        component: DefaultLayoutComponent,
        children: [
          {
            path: 'attendance',
            component: AttendanceListComponent,
          }
        ]
      },
      {
        path: 'employee',
        component: DefaultLayoutComponent,
        children: [
          {
          path:'report',
          component: EmployeeMonthlyReportComponent,
          }
        ]
      },
      {
        path: 'employee',
        component: DefaultLayoutComponent,
        children: [
          {
          path:':id',
          component: EmployeeDashboardComponent,
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
