import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {

  constructor(private router: Router, private auth: AuthService, private languageService: LanguageService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isLoggedIn) {
      return true;
    } else {
      this.router.navigate([this.languageService.login]);
      return false;
    }
  }

}

@Injectable()
export class LoginAuthguard {

  constructor(private router: Router, private auth: AuthService, private languageService: LanguageService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isLoggedIn) {
      return true;
    } else {
      this.router.navigate([this.languageService.home]);
      return false;
    }
  }

}
