<div fxflex="100%" fxLayout="row" class="row-padding">
  <div fxFlex="25%" class="textCenter mtm-10">
    <div class="employeeProfile">
      <div class="field-exec-list">
        <form [formGroup]="employeeSelectionForm" #frmEmployeeSelection="ngForm" fxFlex fxLayout="column" autocomplete="off">
          <mat-form-field *ngIf="projectList">
            <mat-label>Project</mat-label>
            <mat-select formControlName="pid" placeholder="Select Project" (selectionChange)="getProjectMembers($event.value)">
              <mat-option value="" selected>All Projects</mat-option>
              <mat-option *ngFor="let p of projectList" [value]="p">
                {{ p.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxFlex="100%" style="padding: 0px 17px;" fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex="95%">
                <b>{{this.projectEmployeeList? this.projectEmployeeList.length : ''}} EMPLOYEES</b>
            </div>
            <div fxFlex="5%">
              <mat-checkbox (change)="toggleSelectAll($event)" [checked]="checked"></mat-checkbox>
            </div>
          </div>
          <span *ngIf="!projectEmployeeList" style="text-align: center;">Loading</span>
          <mat-selection-list *ngIf="projectEmployeeList" [formControlName]="'employees'">
            <mat-list-option style="text-transform: capitalize;" *ngFor="let employee of projectEmployeeList;let i=index" [selected]="employee.isSelected" (click)="toggleSingleSelect(employee)">
              {{employee._id}} - {{employee.name }}
            </mat-list-option>
          </mat-selection-list>
        </form>
      </div>
    </div>
  </div>
  <div fxFlex="75%" class="mtm-10" fxLayout="column">
    <div fxLayout="column" class="info">
      <h4 *ngIf="selectedProject?.name">{{selectedProject?.name}}</h4>
      <p *ngIf="selectedProject?.desc">{{selectedProject?.desc}}</p>
    </div>
    <!-- Result -->
    <!-- <div>Attendance List</div> -->
    <data-grid
      [pageTitle]="pageTitle"
      [page]="page"
      [pageData]="pageData"
      [rptTemplate]="rptTemplate"
      [groupBy]="groupBy"
      [empIds]="selectedEmployeeIds"
      *ngIf="pageData"></data-grid>
  </div>
</div>
