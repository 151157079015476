import { DataGridComponent } from './../data-grid/data-grid.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'employee-monthly-report',
  templateUrl: './employee-monthly-report.html',
  styleUrls: ['./employee-monthly-report.scss']
})
export class EmployeeMonthlyReportComponent implements OnInit, AfterViewInit {
  pageTitle = 'Employee-wise Attendance Report';
  page = 'attendance-list';
  groupBy = "emp_id"
  rptTemplate = "attendance"
  pageData:any
  employeeList:any
  projectList:any
  projectEmployeeList:[]
  selectedProject:any
  employeeSelectionForm: FormGroup;
  selectedEmployeeIds:any
  reqSubscription: Subscription[] = [];
  checked: boolean = true;
  startDate = moment().startOf('day')
  endDate = moment().endOf('day')

  @ViewChild('DataGridComponent',{static:true}) dataGrid: DataGridComponent
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    formBuilder: FormBuilder,
    private dataService: DataService) {
      this.dataService.getEmployeeList().subscribe(res=>{
        this.employeeList = res
        this.getProjectMembers()
      })
      this.dataService.getProjectList().subscribe(res=>{
        this.projectList = res
      })
     // this.selectedProject
     this.employeeSelectionForm = formBuilder.group({
      pid: new FormControl(""),
      employees: this.selectedEmployeeIds
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
  }

  getProjectMembers(project?) {
 
    if (project)
      this.projectEmployeeList = this.employeeList.filter(o=>o.pid==project._id)
    else //all employees
      this.projectEmployeeList = this.employeeList
    //by deault selected all the employees
    this.checked = true
    this.projectEmployeeList.map((emp:any)=>{emp["isSelected"] = true})
    this.getSelectedEmployees(project)
  }

  getSelectedEmployees(project?) {
  
    this.selectedEmployeeIds = []
    this.projectEmployeeList.forEach((e:any)=>{
      if (e["isSelected"])
         this.selectedEmployeeIds.push(e['_id'])
    })
    this.selectedEmployeeIds = [...this.selectedEmployeeIds]
    //load the attendance data for selected employees
    this.loadAttendanceData()
  }

  loadAttendanceData() {
    
    if (this.selectedEmployeeIds.length==0) {
      this.pageData = []
      return
    }
    this.reqSubscription.push(
      this.dataService.getEmployeeAttendanceList(this.startDate.format(), this.endDate.format(),this.selectedEmployeeIds).subscribe(response=>{
        this.pageData = response
      })
  )
  }


  toggleSelectAll(event) {
    this.checked = !this.checked;
    if(event.checked) {
      this.projectEmployeeList.map((emp:any)=>{emp["isSelected"] = true})
    } else {
      this.projectEmployeeList.map((emp:any)=>{emp["isSelected"] = false})
    }
    this.getSelectedEmployees()
  }

  toggleSingleSelect(emp) {
    emp["isSelected"] = !emp["isSelected"]
    if (this.projectEmployeeList.filter(e=>e["isSelected"]==true).length == this.projectEmployeeList.length) {
      this.checked = true
    } else {
      this.checked = false
    }
    this.getSelectedEmployees()
  }


  ngOnDestroy(): void {
    this.reqSubscription.forEach(sub => sub.unsubscribe())
  }

}
