
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackbarService } from '../snackbar/snackbar.service';
import { AuthService } from '../auth.service';


@Injectable()
export class InterceptorService implements HttpInterceptor {
  messageCodes: any;
  isArchive: any = true;
  selectedOrgId:string = localStorage.getItem("selectedOrgId")
  constructor(
    private snackbar: SnackbarService,
    private authService : AuthService,
  ) {
    this.isArchive = true;
    this.authService.selectedOrgId.subscribe(id=>{
      this.selectedOrgId = id
    })
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token
    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    if (request.headers.has('image')) {
      request = request.clone({ headers: request.headers.delete('content-Type') });
      request = request.clone({ headers: request.headers.delete('image') });
      request = request.clone({ headers: request.headers.set('Accept', '/') });
    }
    if (this.selectedOrgId) {
      request  = request.clone({ headers: request.headers.set('OrgId', this.selectedOrgId) });
    }
    return next.handle(request).pipe(
      // REST API Error handler
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
              if (error.error || error.error.message) {
                this.snackbar.snackbarError(error.error.message ? error.error.message : error.error, 'center');
              } else {
                this.snackbar.snackbarError('Status 400 error.', 'center');
              }
              return throwError(error);
            case 401:
              if (error.error || error.error.message) {
                this.snackbar.snackbarError(error.error.message ? error.error.message : error.error, 'center');
              } else {
                this.snackbar.snackbarError('Unauthorized', 'center');
              }
              return throwError(error);
            case 500:
              if (error.error || error.error.message) {
                this.snackbar.snackbarError(error.error.message ? error.error.message : error.error, 'center');
              } else {
                this.snackbar.snackbarError('Internal Server Error', 'center');
              }
              return throwError(error);
            case 404:
              if (error.error || error.error.message) {
                this.snackbar.snackbarError(error.error.message ? error.error.message : error.error, 'center');
              } else {
                this.snackbar.snackbarError('Not Found', 'center');
              }
              console.log(error);
              return throwError(error);
            case 410:
              if (error.error || error.error.message) {
                this.snackbar.snackbarError(error.error.message ? error.error.message : error.error, 'center');
              } else {
                this.snackbar.snackbarError('Status 410 error.', 'center');
              }
              console.log(error);
              return throwError(error);
            default:
              this.snackbar.snackbarError(error && error.error && error.error.message, 'center');
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
        }
        return evt;
      }));
  }
}