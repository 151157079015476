
<div *ngIf="show_spinner">
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" >Your request is being updating... </p></ngx-spinner>
</div>


<div fxLayout="row" *ngIf="this._emp">

  <!-- <div style="align-content: flex-end;" *ngIf="this._emp['last_seen']">
    <mat-slide-toggle (change)="toggleTrackingMap($event)">View Movement</mat-slide-toggle>
  </div> -->
  <div class="showDate">
    <mat-form-field>
      <mat-label>Date</mat-label>
      <input [(ngModel)]="currentDate" (dateChange)="dateChange($event)" matInput [matDatepicker]="dp1"
        [max]="_calMaxDate">
      <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
      <mat-datepicker #dp1 disabled="false"></mat-datepicker>
    </mat-form-field>
    <mat-checkbox class="checksignIn" [checked]="isDefault" (change)="checksignIn($event.checked)">Show Other time
      Location</mat-checkbox>
  </div>
  <div fxFlex="0 1 auto" style="margin-left: auto; margin-right:20px;padding-top: 24px;">
    <span><img style="width: 30px !important;" src="assets/images/start-position.png" /> Login</span>
    <span><img style="width: 30px !important;" src="assets/images/logout.png" /> Logout</span>
    <span style="margin-right:30px;">&nbsp;&nbsp;</span>
    <span><img style="width: 30px !important;" src="assets/images/self-position.png" /><b>{{dateString}}</b></span>
  </div>




</div>
<div style="height:745px; width: 99%" leaflet id="emap" [leafletOptions]="options" [(leafletCenter)]="mapCenter"
  [leafletLayers]="allMarkers" [(leafletZoom)]="mapZoom" (leafletClick)="setCurrentLocation($event)"
  (contextmenu)="openPopUp($event)">
</div>

<div style="visibility: hidden; position: fixed;" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" *ngIf="this.dataobjects?.role > 1"></div>

<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngIf="showAddSignButtons">
      <button mat-menu-item (click)="addSignInOut('in')">Add Sign-In</button>
      <button mat-menu-item (click)="addSignInOut('out')">Add Sign-out</button>
      <button mat-menu-item (click)="addSignInOut('ping')">Add Ping</button>
    </ng-container>
    <ng-container *ngIf="showDeleteButton">
      <button mat-menu-item (click)="deletePing(this.index,this.actionType,this.iconData)">Remove ping</button>
    </ng-container>
  </ng-template>
</mat-menu>


<ng-template #attendanceAddDialog let-data>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle style="text-align:center">Add {{Attendance}}</h2>
  <mat-dialog-content>
    <dynamic-form [formname]="this.formName" [hideSendButton]="hideSendButton" [attendanceInfo]="data"
      [empid]="this._emp._id" [id]="this._emp._id"></dynamic-form>
  </mat-dialog-content>
