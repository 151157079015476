import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { WithoutTopmenuComponent } from './without-topmenu/without-topmenu.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppBodyComponent } from './app-body.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { HighLightPipe } from './highlight.pipe';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';


@NgModule({
  declarations: [
    WithoutTopmenuComponent,
    DefaultLayoutComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppBodyComponent,
    HighLightPipe,
    MenuListItemComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule
  ],
  exports:[
    WithoutTopmenuComponent,
    DefaultLayoutComponent,
    MenuListItemComponent,
    AppHeaderComponent,
    AppBodyComponent,
    AppFooterComponent]
})
export class AppLayoutModule { }
