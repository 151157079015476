import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { DynamicMatTableComponent } from './components/dynamic-mat-table/dynamic-mat-table.component';
import { RouterModule } from '@angular/router';
import { CdkTableModule } from "@angular/cdk/table";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { AppLayoutModule } from './components/layout/app-layout.module';
import { FormioModule } from '@formio/angular';



@NgModule({
  declarations: [
    DynamicMatTableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    DragDropModule,
    CdkTableModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveValidationModule,
    AppLayoutModule,
    FormioModule
  ],
  exports: [
    DynamicMatTableComponent
  ],
  providers:[
  ]
})
export class SharedModule { }
