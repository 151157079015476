<div fxflex="100%" fxLayout="row" class="row-padding">
  <div fxFlex="20%" class="textCenter mtm-10">
    <div class="employeeProfile">
      <div class="field-exec-list">
        <form [formGroup]="searchUserForm" #frmsearch="ngForm" fxFlex fxLayout="column" autocomplete="off">
          <mat-form-field>
            <mat-label>Project</mat-label>
            <mat-select formControlName="pid" placeholder="Select Project" (selectionChange)="getEmployeeDetails($event)">
              <mat-option *ngFor="let p of projectList" [value]="p._id">
                {{ p.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <h6><b>EMPLOYEES</b></h6>
          <span *ngIf="!employeeList.length" style="text-align: center;">Employee(s) not assigned to this project yet</span>
          <mat-selection-list [formControlName]="'employee'">
            <mat-list-option style="text-transform: capitalize;" *ngFor="let employee of employeeList;let i=index"
              [selected]="employee.is_select" (click)="toggleAllSelection(i)">
              {{employee.name }}
            </mat-list-option>
          </mat-selection-list>
        </form>
      </div>
    </div>
  </div>
  <div fxFlex="80%" class="mtm-10" fxLayout="column">
    <div fxLayout="column" class="info">
      <h4 *ngIf="selectedProject?.name">{{selectedProject?.name}}</h4>
      <p *ngIf="selectedProject?.desc">{{selectedProject?.desc}}</p>
    </div>
    <div style="height: 500px; width: 99%" leaflet 
    [leafletOptions]="options"  
    [(leafletCenter)]="mapCenter" 
    [leafletLayers]="markers"
    [leafletFitBounds]="leafletFitBounds"
    [(leafletZoom)] ="mapZoom"
    >
    </div>
  </div>
</div>